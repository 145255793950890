import { FC, useCallback, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { AutoCompleteField, AutoCompleteOption } from 'components/shared/forms/AutoCompleteField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Vehicle, Vehicles } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import { getCarClassAndDescription } from 'utils/vehicleUtils';
import _ from 'lodash';
import { EMPTY_VALUE } from 'utils/constants';

export const VEHICLE_CLASS_NAME = 'vehicleClass';

export type VehicleAutoCompleteProps = {
  vehicles: Vehicles;
  setVehicle: (vehicle: string) => void;
  resetVehicle: () => void;
  isLoading?: boolean;
  selectedVehicleClass?: string;
  disabled?: boolean;
};

export const VehicleAutoCompleteField: FC<VehicleAutoCompleteProps> = ({
  vehicles,
  isLoading = false,
  setVehicle,
  resetVehicle,
  selectedVehicleClass = EMPTY_VALUE,
  disabled = false,
}) => {
  const { t } = useTranslations();

  const autocompleteOptions = useMemo(() => {
    const vehicleOptions = vehicles.map(
      (vehicle: Vehicle): AutoCompleteOption => ({
        key: vehicle.vehicleClass,
        title: getCarClassAndDescription(vehicle.vehicleClass, vehicles),
      })
    );
    return _.uniqBy(vehicleOptions, 'title');
  }, [vehicles]);

  const getSelectedTitle = useCallback(() => {
    if (!autocompleteOptions || !selectedVehicleClass) {
      return EMPTY_VALUE;
    }

    const selectedOption = autocompleteOptions.find((option) => option.key === selectedVehicleClass);

    return selectedOption?.title ?? EMPTY_VALUE;
  }, [autocompleteOptions, selectedVehicleClass]);

  return (
    <AutoCompleteField
      name={VEHICLE_CLASS_NAME}
      data-testid={'vehicleSearchAutocomplete'}
      value={{
        title: getSelectedTitle(),
        key: selectedVehicleClass,
      }}
      disabled={disabled}
      placeholder={t('vehicle.classOrSIPPCode')}
      handleOnChange={(value: AutoCompleteOption): void => setVehicle(value.key)}
      options={autocompleteOptions}
      loading={isLoading}
      handleRemoveOption={resetVehicle}
      startAdornment={<InputIconButton position={'start'} icon={<SearchIcon />} />}
    />
  );
};
