import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { AddressType, AddressV2, BranchV2, PhoneType } from 'services/location/locationTypes';

export type FormattedAddress = {
  addressType: AddressType;
  phoneType: PhoneType;
  addrStationId: string | undefined;
  addrStationName: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressCity: string | undefined;
  addressState: string | undefined;
  addressCountry: string | undefined;
  addressPostalCode: string | undefined;
  addressPostalCodeExt: string | undefined;
  formattedPhone: string;
  addressPostalLine: string;
};

function getAddressPostalLine(branchAddress: AddressV2 | undefined): string {
  let returnPostalLine =
    branchAddress?.city +
    ', ' +
    branchAddress?.countrySubdivision?.abbreviation +
    ', ' +
    branchAddress?.country?.code +
    ', ' +
    branchAddress?.postalCode?.code;

  if (branchAddress?.postalCode?.extension != undefined) {
    returnPostalLine += '-' + branchAddress.postalCode.extension;
  }
  return returnPostalLine.toString();
}

export const transformToFormattedAddress = (
  branch: BranchV2 | undefined,
  addressType: AddressType,
  phoneType: PhoneType
): FormattedAddress => {
  const branchAddress = branch?.addresses?.find((addr) => addr.type === addressType);
  const phone = branch?.phones?.find((telephone) => telephone.type === phoneType);

  return {
    addressType: addressType,
    phoneType: phoneType,
    addrStationId: branch?.stationId,
    addrStationName: branch?.operationalDescription,
    addressLine1: branchAddress?.line1,
    addressLine2: branchAddress?.line2,
    addressCity: branchAddress?.city,
    addressState: branchAddress?.countrySubdivision?.abbreviation,
    addressCountry: branchAddress?.country?.code,
    addressPostalCode: branchAddress?.postalCode?.code?.toString(),
    addressPostalCodeExt: branchAddress?.postalCode?.extension,
    formattedPhone: formatPhoneNumber(phone?.number),
    addressPostalLine: getAddressPostalLine(branchAddress),
  };
};
