import { EHI_DOMAINS, parseUrn, parseUrnItem } from 'utils/urnUtils';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectBillingAccountPayer, selectRateSource } from 'redux/selectors/bookingEditor';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import {
  RateAndBillingInfo,
  transformRateSourceInfoFromApi,
} from 'components/shared/uiModels/rateSource/rateSourceTransformer';
import { EMPTY_VALUE } from 'utils/constants';

type UseAccountDetailsReturn = {
  accountNumber: string;
  rateSourceInfo: RateAndBillingInfo | undefined;
  isRateSourceInfoLoading: boolean;
  rateSourceErrors: EhiErrors | null;
  billingAccountNumber: string | undefined;
  billingNumber: string | undefined;
  billingAccountInfo: RateAndBillingInfo | undefined;
  isBillingAccountInfoLoading: boolean;
  billingAccountInfoErrors: EhiErrors | null;
};

export const useAccountDetails = (): UseAccountDetailsReturn => {
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const accountNumber = parseUrn(negotiatedRateSource?.account);

  const billingAccountPayer = useAppSelector(selectBillingAccountPayer);
  const billingAccountNumber =
    billingAccountPayer && parseUrnItem(billingAccountPayer, EHI_DOMAINS.businessAccount.account);
  const billingNumber = parseUrn(billingAccountPayer);

  const {
    data: accountDetails,
    isFetching: isAccountDetailsLoading,
    error: accountErrors,
  } = useBusinessAccountQuery(accountNumber, {
    enabled: !!negotiatedRateSource && accountNumber.length > 0,
  });

  const {
    data: billingAccountDetails,
    isFetching: isBillingAccountDetailsLoading,
    error: billingAccountErrors,
  } = useBusinessAccountQuery(billingAccountNumber ?? EMPTY_VALUE, {
    enabled: !!billingAccountNumber && billingAccountNumber.length > 0,
  });

  return {
    accountNumber,
    rateSourceInfo: accountDetails ? transformRateSourceInfoFromApi(accountDetails) : undefined,
    isRateSourceInfoLoading: isAccountDetailsLoading,
    rateSourceErrors: accountErrors,
    billingAccountNumber,
    billingNumber: billingNumber.length ? billingNumber : undefined,
    billingAccountInfo: billingAccountDetails ? transformRateSourceInfoFromApi(billingAccountDetails) : undefined,
    isBillingAccountInfoLoading: isBillingAccountDetailsLoading,
    billingAccountInfoErrors: billingAccountErrors,
  };
};
