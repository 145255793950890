import { FromEditor, FromRetrieve } from 'redux/slices/booking/bookingEditorSliceTypes';
import { EditorAddOns, EditorPayers, PersonPayer, Reservation } from 'services/booking/bookingTypes';
import { ReservationData } from 'components/shared/uiModels/reservation/reservationTypes';

export const transformToReservationData = ({ reservation, editor }: FromRetrieve | FromEditor): ReservationData => {
  if (reservation) {
    return {
      ...reservation,
      dataType: 'Reservation',
      addOns: transformAddonsFromRes(reservation),
      payers: transformPayersFromRes(reservation),
    };
  } else if (editor) {
    return {
      ...editor,
      dataType: 'Editor',
    };
  } else {
    throw new Error('NOTHING TO TRANSFORM TO Re');
  }
};

export const transformAddonsFromRes = (res: Reservation): EditorAddOns => {
  return {
    equipment: { selected: res.addOns?.equipment },
    protections: { selected: res.addOns?.protections },
    ancillaryFees: { applied: res.addOns?.ancillaryFees?.applied, contingent: res.addOns?.ancillaryFees?.contingent },
    fuelService: false,
  };
};

export const transformPayersFromRes = (res: Reservation): EditorPayers => {
  return {
    person: res.payers?.person || ({} as PersonPayer),
    business: res.payers?.business,
    billingReference: res.payers?.billingReference,
  };
};
