import { FieldErrors } from 'react-hook-form';
import { AnyObjectSchema, AnySchema, ValidationError } from 'yup';

// The field errors type here just makes the resolver happy,
// it's really the same with or without
// (our stuff just doesn't need to be as complicated)
type GenericErrors<T extends Record<string, any>> = FieldErrors<T> & {
  [K in keyof T]: { message: string };
};

export const getYupError = async <T extends AnySchema, S>(
  validationSchema: T,
  data: S
): Promise<string | undefined> => {
  try {
    validationSchema.validateSync(data, {
      abortEarly: false,
    });
    await validationSchema.validate(data, {
      abortEarly: false,
    });

    return undefined;
  } catch (errors) {
    const valErrors = errors as ValidationError;

    return valErrors.message;
  }
};

export const getYupErrors = async <T extends AnyObjectSchema, S extends object>(
  validationSchema: T,
  data: S
): Promise<undefined | GenericErrors<S>> => {
  try {
    await validationSchema.validate(data, {
      abortEarly: false,
    });

    return undefined;
  } catch (errors) {
    const valErrors = errors as ValidationError;

    return valErrors.inner?.reduce((allErrors: GenericErrors<S>, currentError) => {
      if (currentError.path) {
        return {
          ...allErrors,
          [currentError.path]: {
            message: currentError.message,
          },
        };
      } else {
        return allErrors;
      }
    }, {} as GenericErrors<S>);
  }
};
