import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { AlertDialog } from 'components/shared/alert/AlertDialog';
import { FC } from 'react';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { useRefreshEditor } from 'hooks/bookingEditor/useRefreshEditor';
import { RouterPaths } from 'app/router/RouterPaths';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';

export type RefreshEditorDialogProps = {
  message: string;
  onClose: () => void;
};

export const RefreshEditorDialog: FC<RefreshEditorDialogProps> = ({ onClose, message }) => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { refreshEditor } = useRefreshEditor();
  const navigate = useNavigate();
  const { clearEditorSession } = useReservationSessionHelper();
  return (
    <AlertDialog
      open
      title={t('error.error')}
      description={t('error.editorError', { error: message })}
      primaryActionText={t('error.reload')}
      secondaryActionText={t('error.home')}
      onClose={(selected?: SelectedAction): void => {
        if (selected === SelectedAction.Primary) {
          refreshEditor(bookingEditorId);
        } else if (selected === SelectedAction.Secondary) {
          clearEditorSession();
          navigate(RouterPaths.Search);
        }
        onClose();
      }}
    />
  );
};
