import { createContext, FC, useCallback, useContext, useEffect, useRef, ReactNode } from 'react';
import { NavigateFunction, To, useLocation } from 'react-router-dom';
import { SaveActionContextValue, SaveOptions } from 'context/saveAction/SaveActionContextTypes';
import { logDebug } from 'utils/logUtils';

const SaveActionContext = createContext<SaveActionContextValue | undefined>(undefined);

export const useSaveActionContext = (): SaveActionContextValue => {
  const context = useContext(SaveActionContext);

  if (!context) {
    throw Error('SaveActionContext is not initialized');
  }

  return context;
};

export const SaveActionProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  // use ref so that registration doesn't cause infinite re-renders
  const saveOptionsRef = useRef<SaveOptions | undefined>(undefined);
  const savePath = useRef<string | undefined>(undefined);
  const { pathname } = useLocation();

  useEffect(() => {
    // clear out save options when path changes
    if (savePath.current && pathname !== savePath.current) {
      savePath.current = pathname;
      saveOptionsRef.current = undefined;
    }
  }, [pathname]);

  const registerSaveAction = useCallback(
    (saveOptions: SaveOptions) => {
      savePath.current = pathname;
      saveOptionsRef.current = saveOptions;
    },
    [pathname]
  );

  const saveOnNavigate = useCallback(
    async (
      navigate: NavigateFunction,
      to: To | -1,
      options?: {
        replace?: boolean;
        state?: any;
      }
    ) => {
      if (saveOptionsRef?.current?.when) {
        try {
          const result = await saveOptionsRef.current.onSave();

          if (!result?.errors?.length) {
            saveOptionsRef.current = undefined;
            navigate(to as any, options);
          }
        } catch (er) {
          // Generally ignore this, but a log might be handy for debugging
          logDebug('Save and navigate failed', er);
        }
      } else {
        saveOptionsRef.current = undefined;
        navigate(to as any, options);
      }
    },
    []
  );

  const saveOnUpdate = useCallback(async () => {
    if (saveOptionsRef?.current?.when) {
      try {
        const result = await saveOptionsRef.current.onSave();

        if (!result?.errors?.length) {
          // Once we update the booking editor, the form will still contain dirty fields
          // Resetting the form with the current state will set a new default and clear dirty fields
          saveOptionsRef.current.reset();
        }
      } catch (er) {
        // Generally ignore this, but a log might be handy for debugging
        logDebug('Save on Update click failed', er);
      }
    }
  }, []);

  return (
    <SaveActionContext.Provider value={{ registerSaveAction, saveOnNavigate, saveOnUpdate }}>
      {children}
    </SaveActionContext.Provider>
  );
};
