import { ActionOptions, Body1, EhiAlertDialog } from '@ehi/ui';
import { FC } from 'react';
import { AlertDialogProps, BaseDialogProps, SelectedAction } from './AlertDialogTypes';
import { Box } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { useDialogMenuItems } from 'components/shared/ui/dialogs/useDialogMenuItems';
import { Menu } from 'components/shared/ui/moreMenu/Menu';

export const AlertDialog: FC<BaseDialogProps & AlertDialogProps> = ({
  title,
  subtitle,
  primaryActionText,
  secondaryActionText,
  tertiaryActionText,
  open,
  onClose,
  description,
  descriptionStyle,
  showMoreMenu = true,
  variant = 'alert',
  collectErrorInformation,
}) => {
  const { t } = useTranslations();
  const moreMenuItems = useDialogMenuItems(onClose, collectErrorInformation);

  title =
    title || (variant === 'error' && t('error.error')) || (variant === 'warning' && t('error.warning')) || undefined;

  const content = (
    <Box style={descriptionStyle} minWidth='20rem' data-testid='alert-content'>
      {subtitle && <Body1 bold>{subtitle}</Body1>}
      {description}
    </Box>
  );

  const actions: ActionOptions = {
    primaryAction: {
      label: primaryActionText || t('common.close'),
      type: variant === 'destructive' ? 'error' : 'standard',
      onClick: () => onClose?.(SelectedAction.Primary),
    },
  };

  if (secondaryActionText) {
    actions.secondaryAction = {
      label: secondaryActionText,
      onClick: () => onClose?.(SelectedAction.Secondary),
    };
  }

  if (tertiaryActionText) {
    actions.tertiaryAction = {
      label: tertiaryActionText,
      onClick: () => onClose?.(SelectedAction.Tertiary),
    };
  }

  return (
    <EhiAlertDialog
      style={{ whiteSpace: 'pre-line' }}
      open={open}
      onClose={(): void => onClose?.()}
      title={title}
      description={content}
      titleActions={showMoreMenu ? [<Menu menuItems={moreMenuItems} key={'more-menu'} />] : undefined}
      actions={actions}></EhiAlertDialog>
  );
};
