import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import { RetrieveVehicleRatesResponse } from 'services/booking/bookingTypes';
import { retrieveVehicleRates } from 'services/booking/bookingService';

const VEHICLE_RATES_QUERY_KEY = 'vehicleRates';

export const useVehicleRatesQuery = (
  editorId: string,
  vehicleRatesKey: string,
  queryOptions: Partial<UseQueryOptions<RetrieveVehicleRatesResponse, EhiErrors>> = {}
): UseQueryResult<RetrieveVehicleRatesResponse, EhiErrors> => {
  const queryKey = [VEHICLE_RATES_QUERY_KEY, vehicleRatesKey];
  const queryFn = (): Promise<RetrieveVehicleRatesResponse> => retrieveVehicleRates(editorId);
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    ...queryOptions,
  };

  return useQuery<RetrieveVehicleRatesResponse, EhiErrors>(queryKey, queryFn, options);
};
