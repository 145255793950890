import { useCallback } from 'react';
import {
  AdditionalInfoAccountType,
  SaveAdditionalInfoHookReturn,
  SaveRequestsTypes,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { BusinessPayer, EditorBusinessPayer, RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource, updateBusinessPayers } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { BillingAccountType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { generateUrnForBillingAccount } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { validateAdditionalInformation } from 'services/businessAccount/businessAccountService';
import { RentalStage } from 'services/businessAccount/businessAccountTypes';
import { ACCOUNT_TYPE_NEGOTIATED } from 'utils/rateAndBillingUtils';
import { useAlert } from 'components/shared/alert/AlertContext';

export const useSaveAdditionalInformation = (): SaveAdditionalInfoHookReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';

  const saveAdditionalInfo = useCallback(
    async (saveAdditionalInfo: SaveRequestsTypes): Promise<void> => {
      const validateInputFieldsPayload = {
        ignoreMissingRequiredFields: true,
        currentRentalStage: RentalStage.RESERVATION,
        additionalInformationValues:
          saveAdditionalInfo.additionalInformation.length > 0
            ? saveAdditionalInfo.additionalInformation.map((info) => {
                return {
                  additionalInformation: info.fieldId,
                  value: info.value,
                };
              })
            : [],
      };
      const isValid = await validateAdditionalInformation(saveAdditionalInfo.accountNumber, validateInputFieldsPayload);
      // Handle errors from validation api ehi messages in the future story
      if (isValid) {
        if (saveAdditionalInfo.type === AdditionalInfoAccountType.RATE_SOURCE) {
          const rateSourceRequestBody: RateSource = {
            type: ACCOUNT_TYPE_NEGOTIATED,
            account: saveAdditionalInfo.account,
            additionalInformation: saveAdditionalInfo.additionalInformation,
          };

          const { errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody));
          if (errors) {
            await showAlert({ responseMessages: errors });
          }
        } else if (saveAdditionalInfo.type === AdditionalInfoAccountType.BILL_TO) {
          const billToRequestBody: EditorBusinessPayer[] = [
            {
              type: BillingAccountType.BILLING_ACCOUNT,
              billingAccount: generateUrnForBillingAccount(
                saveAdditionalInfo.accountNumber,
                saveAdditionalInfo.billingNumber,
                defaultEhiDatabase
              ),
              additionalInformation: saveAdditionalInfo.additionalInformation,
            } as BusinessPayer,
          ];

          const { errors } = await updateAndRefresh(async () =>
            updateBusinessPayers(bookingEditorId, billToRequestBody)
          );
          if (errors) {
            await showAlert({ responseMessages: errors });
          }
        }
      }
    },
    [updateAndRefresh, bookingEditorId, defaultEhiDatabase, showAlert]
  );

  return {
    saveAdditionalInfo,
  };
};
