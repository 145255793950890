import parse, { IResult } from 'ua-parser-js';

export function parseUserAgent(): IResult {
  return parse(navigator.userAgent);
}

export function userAgent(): string {
  return navigator.userAgent.split(' ')[0];
}

export function getScreen(): Screen {
  return window.screen;
}
