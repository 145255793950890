import { styled } from '@mui/material';
import { Caption as UiCaption, H6, Overline } from '@ehi/ui';

export const Title = styled(H6)`
  color: ${({ theme }): string => theme.palette.text.primary};
`;

/**
 * Caption defaulted to secondary color
 */
export const Caption2 = styled(UiCaption)``;
Caption2.defaultProps = {
  color: 'secondary',
};

export const OverlineText = styled(Overline)`
  line-height: ${({ theme }): string => theme.spacing(2)};
  letter-spacing: ${({ theme }): string => theme.spacing(0.188)};
`;
