import { useAlert } from 'components/shared/alert/AlertContext';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useMemo } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableEquipment, selectBookingEditorId, selectEquipment } from 'redux/selectors/bookingEditor';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { updateEquipment } from 'services/booking/bookingService';
import { useEquipmentTypesQuery } from 'services/rentalReference/rentalReferenceQueries';
import {
  BaseEquipment,
  EquipmentFormFields,
  EquipmentFormModel,
  EquipmentItemFields,
  PEOResponse,
} from 'components/flexFlow/peo/peoTypes';
import { BLOCK_EQUIPMENT_CODES, equipmentInitialValues, formattedEquipmentTypes } from 'utils/peoUtils';
import { Equipment } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';

export type SaveEquipment = {
  saveEquipmentAndRefreshEditor: (equipmentModal: EquipmentFormModel) => Promise<ServiceResultType>;
  equipmentValues: EquipmentFormModel;
};

export const useSaveEquipment = (): SaveEquipment => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const equipment = useAppSelector(selectEquipment);
  const availableEquipment = useAppSelector(selectAvailableEquipment);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { data: equipmentDetails = [] } = useEquipmentTypesQuery();

  const equipmentValues: EquipmentFormModel = useMemo(() => {
    const selectedEquipments = formattedEquipmentTypes(equipment, equipmentDetails);
    const types = selectedEquipments.map((item: BaseEquipment) => {
      return item.type;
    });
    const unBlockedEquipments = availableEquipment?.filter((item) => {
      const equipmentCode = parseUrn(item.type);
      return !BLOCK_EQUIPMENT_CODES.includes(equipmentCode);
    });

    const available = unBlockedEquipments?.filter((item: Equipment) => {
      if (item.type) return !types.includes(item.type);
    });
    const availableEquipments = formattedEquipmentTypes(available, equipmentDetails);

    return equipmentInitialValues(selectedEquipments, availableEquipments);
  }, [availableEquipment, equipment, equipmentDetails]);

  const saveEquipmentAndRefreshEditor = async (equipmentModal: EquipmentFormModel): Promise<PEOResponse> => {
    const equipmentUpdates = equipmentModal[EquipmentFormFields.Items]
      ?.filter((protectionItemModel) => protectionItemModel[EquipmentItemFields.Checked])
      ?.map((protectionItemModel) => {
        const item = protectionItemModel[EquipmentItemFields.Item];
        const quantity = protectionItemModel[EquipmentItemFields.Quantity];
        return Array.from(Array(quantity)).map(() => {
          return { type: item.type };
        });
      })
      .flat();

    const { errors } = await updateAndRefresh(async (): Promise<void> => {
      await updateEquipment(bookingEditorId, equipmentUpdates);
    });
    if (errors) {
      await showAlert({ responseMessages: errors });
    }
    return { success: !errors, errors: errors };
  };

  return {
    saveEquipmentAndRefreshEditor,
    equipmentValues,
  };
};
