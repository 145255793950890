import { styled } from '@mui/material';
import { Body2, ehiTheme } from '@ehi/ui';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';

export const AlertContainer = styled('div')`
  position: sticky;
  height: 48px;
  z-index: 3;
  width: 60%;
  margin: ${ehiTheme.spacing(2, 0, 1.25, 0)};
  align-items: flex-start;
  justify-self: center;
  border-radius: 4px;
  background: ${ehiTheme.palette.background.paper};
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  & .Mui-expanded {
    border-radius: 4px 4px 0 0;
  }
`;

export const AlertAccordion = styled(Accordion)`
  width: 100%;
  background: ${ehiTheme.palette.background.paper};
  border-right: 1px solid ${ehiTheme.palette.info.main};
  border-left: 1px solid ${ehiTheme.palette.info.main};
  border-bottom: 1px solid ${ehiTheme.palette.info.main};

  & .MuiAccordionSummary-content {
    margin: ${ehiTheme.spacing(1.5, 0)};
  }
  & .Mui-expanded {
    border-radius: 4px 4px 0 0;
  }
`;

export const AlertAccordionSummary = styled(AccordionSummary)`
  background: ${ehiTheme.palette.info.main};
  border-radius: 4px 4px 0 0;
  color: ${ehiTheme.palette.info.contrastText};

  :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), ${ehiTheme.palette.info.main};
  }
`;

export const AlertListItem = styled(ListItem)`
  flex-direction: column;
  align-items: start;
  padding: ${ehiTheme.spacing(2, 3, 2.5, 3)};
  background: rgba(106, 62, 157, 0.08);

  &:last-child = {
    border-radius: 0 0 4px 4px;
  }
`;

export const AlertTitle = styled(Body2)`
  display: flex;
  margin-left: ${ehiTheme.spacing(1)};
  align-items: center;
  gap: ${ehiTheme.spacing(1)};
  align-self: stretch;
`;

export const AlertBadge = styled(Badge)`
  & .MuiBadge-badge {
    background: ${ehiTheme.palette.error.main};
    margin-top: ${ehiTheme.spacing(0.25)};
  }
`;
