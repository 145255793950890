import { FC, useCallback, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';
import { combineDateAndTime, isInvalidDateTime } from 'utils/dateUtils';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectPickup } from 'redux/selectors/bookingEditor';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { Box, Grid } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { logDebug } from 'utils/logUtils';
import { useAlert } from 'components/shared/alert/AlertContext';

const RentalPickup: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const pickupInfo = useAppSelector(selectPickup);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();

  const [loading, setLoading] = useState(false);

  const {
    getValues,
    setValue,
    setError,
    getFieldState,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    startDate,
    startTime,
    returnDate,
    returnTime,
    currentLocationTimezone: timezone,
    currentLocationUrn: locationUrn,
  } = getValues();

  const addPickupDateTime = useCallback(
    async (dateTime: DateTime | undefined) => {
      if (dateTime && dateTime.toISO()) {
        setValue(QuickResFields.StartDateTime, dateTime.toISO());
        if (
          !errors[QuickResFields.StartTime] &&
          !errors[QuickResFields.StartDate] &&
          dateTime.toISO({ suppressMilliseconds: true }) !== pickupInfo?.dateTime
        ) {
          try {
            setLoading(true);
            const { errors: dateTimeErrors } = await updateAndRefresh(async () => {
              await updatePickupInformation(bookingEditorId, {
                branch: locationUrn,
                dateTime: dateTime?.toISO() ?? '',
              });
              // Note: Set to default one day rental if no return date entered
              if (!returnDate && !returnTime) {
                await updateReturnInformation(bookingEditorId, {
                  branch: locationUrn,
                  dateTime: dateTime?.plus({ day: 1 }).setZone(timezone).toISO() || '',
                });
              }
            });
            if (dateTimeErrors) {
              await showAlert({
                variant: 'error',
                description: dateTimeErrors?.[0]?.localizedMessage ?? '',
                primaryActionText: 'dismiss',
              });
            }
          } catch (error) {
            logDebug(error);
          } finally {
            setLoading(false);
          }
        }
      }
    },
    [
      bookingEditorId,
      errors,
      locationUrn,
      pickupInfo?.dateTime,
      returnDate,
      returnTime,
      setValue,
      showAlert,
      timezone,
      updateAndRefresh,
    ]
  );

  const pickupFieldsHaveBeenTouched = useCallback(
    () => getFieldState(QuickResFields.StartDate).isDirty && getFieldState(QuickResFields.StartTime).isDirty,
    [getFieldState]
  );

  const handlePickupDateChange = useCallback(
    async (date: DateTime | '') => {
      if (pickupFieldsHaveBeenTouched()) {
        await trigger([QuickResFields.StartTime]);
        setValue(QuickResFields.StartDate, date);
        const combinedDateTime = date && startTime ? combineDateAndTime(date, startTime, timezone) : undefined;
        await addPickupDateTime(combinedDateTime);
      }
    },
    [addPickupDateTime, pickupFieldsHaveBeenTouched, setValue, startTime, timezone, trigger]
  );

  const handlePickupTimeChange = useCallback(
    async (time: DateTime | '', isOnBlurOrAccept: boolean) => {
      if (isInvalidDateTime(time)) {
        setError(QuickResFields.StartTime, { message: t('validation.invalidTimeFormat') });
      } else if (pickupFieldsHaveBeenTouched()) {
        await trigger([QuickResFields.StartDate]);
        setValue(QuickResFields.StartTime, time);
        if (isOnBlurOrAccept) {
          const combinedDateTime = startDate && time ? combineDateAndTime(startDate, time, timezone) : undefined;
          await addPickupDateTime(combinedDateTime);
        }
      }
    },
    [addPickupDateTime, pickupFieldsHaveBeenTouched, setError, setValue, startDate, t, timezone, trigger]
  );

  return (
    <Box paddingRight={ehiTheme.spacing(0.5)}>
      <h4 style={{ marginBottom: ehiTheme.spacing(2) }}>{t('whenWhere.rentalStart')}</h4>
      <Grid container data-testid={'rentalStartSection'} spacing={2}>
        <Grid item xs={12} sm={12} md={6} pl={0} data-testid={'startDateField'}>
          <DatePickerField
            name={QuickResFields.StartDate}
            label={t('whenWhere.date')}
            autoFocus
            disablePast
            sx={{ padding: ehiTheme.spacing(0) }}
            required
            submitOnChange={handlePickupDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} pl={0} data-testid={'startTimeField'}>
          <TimePickerField
            name={QuickResFields.StartTime}
            label={t('whenWhere.time')}
            timezone={timezone}
            required
            sx={{ padding: ehiTheme.spacing(0) }}
            submitOnChange={(time: DateTime | ''): Promise<void> => handlePickupTimeChange(time, false)}
            onSubmitTime={(time: DateTime | ''): Promise<void> => handlePickupTimeChange(time, true)}
          />
        </Grid>
      </Grid>
      <ProgressOverlay inProgress={loading} />
    </Box>
  );
};

export default RentalPickup;
