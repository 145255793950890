import { FC, useCallback, useMemo, useState } from 'react';
import {
  AccountDetailsContainer,
  RateAndBillingSearchContainer,
  RateAndBillingSearchLabel,
  StyledFlexGrid,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Box, Grid } from '@mui/material';
import { EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { RateSourceFields } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';
import { FieldLoadingIndicator } from 'components/shared/forms/FieldLoadingIndicator';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE, ENTER_KEY_CODE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { transformRateSourceInfoFromApi } from 'components/shared/uiModels/rateSource/rateSourceTransformer';
import { NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectBrand,
  selectRateSource,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useAccountContactInfoQuery, useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { QueryObserverResult } from '@tanstack/react-query';
import { logError } from 'components/shared/logger/splunkLogger';
import { EHI_DOMAINS, generateUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useRateSource } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/useRateSource';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import { transformAccountDetailsFromRetrieve } from 'utils/rateAndBillingUtils';
import { getAllVehicleNotAvailableBookingIssues, hasVehicleAvailabilityBookingIssues } from 'utils/bookingUtils';
import { useBookingIssue } from 'services/booking/useBookingIssue';

type AccountNumberSearchContainerProps = {
  handleCloseModal: () => void;
};

export const getInitialValues = (
  rateProduct?: string,
  accountNumber?: string
): { accountNumber: string; rateProduct: string } => {
  return {
    [RateSourceFields.AccountNumber]: accountNumber ?? EMPTY_VALUE,
    [RateSourceFields.RateProduct]: rateProduct ?? EMPTY_VALUE,
  };
};
export const RateSourceAccountNumberSearch: FC<AccountNumberSearchContainerProps> = ({ handleCloseModal }) => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { addOrModifyRateSource } = useRateSource();
  const { handleRemoveVehicle } = useBookingIssue();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const brand = useAppSelector(selectBrand);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const [loadingAccountNumber, setLoadingAccountNumber] = useState(false);
  const [loadingRateProduct, setLoadingRateProduct] = useState(false);
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const currentRateProduct = parseUrn(rateSource?.rateProduct);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const currentAccountNumber = negotiatedRateSource ? parseUrn(negotiatedRateSource.account) : undefined;

  const initialValues = useMemo(
    () => getInitialValues(currentRateProduct, currentAccountNumber),
    [currentRateProduct, currentAccountNumber]
  );

  const formMethods = useForm({
    defaultValues: initialValues,
  });

  const { setValue, getValues, setError, clearErrors, handleSubmit, formState } = formMethods;
  const [accountNumber, rateProduct] = formMethods.watch([
    RateSourceFields.AccountNumber,
    RateSourceFields.RateProduct,
  ]);

  const { refetch: refetchAccountDetails } = useBusinessAccountQuery(accountNumber, {
    enabled: false,
  });
  const { data: contact } = useAccountContactInfoQuery(currentAccountNumber || EMPTY_VALUE);
  const { data: account } = useBusinessAccountQuery(currentAccountNumber || EMPTY_VALUE);
  const accountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(account, contact);
  }, [account, contact]);
  const handleLogError = useCallback((error: unknown, message: string) => {
    logError({ error, message: message });
  }, []);

  const isOriginalAccount = useMemo(() => {
    return accountNumber === currentAccountNumber;
  }, [currentAccountNumber, accountNumber]);

  const handleApplyingRateProduct = useCallback(
    async (values: FieldValues) => {
      const rateProductValue = values[RateSourceFields.RateProduct];

      try {
        setLoadingRateProduct(true);
        const rateSourceRequestBody: RateSource = {
          type: 'RETAIL',
          rateProduct: rateProductValue
            ? generateUrn(
                EHI_DOMAINS.rentalRate.name,
                `${EHI_DOMAINS.rentalRate.brand}:${parseUrn(brand)}:${EHI_DOMAINS.rentalRate.rateProduct}`,
                rateProductValue.toUpperCase(),
                defaultEhiDatabase
              )
            : undefined,
        };
        const { data, errors } = await updateAndRefresh(
          () => modifyRateSource(bookingEditorId, rateSourceRequestBody),
          {
            availableBookingIssues: [
              ...getAllVehicleNotAvailableBookingIssues(t, t('snackbarMessages.additionalInfo.rateProduct'), vehicle),
            ],
          }
        );
        if (!errors) {
          handleCloseModal();
          if (data?.issue && hasVehicleAvailabilityBookingIssues(data.issue)) {
            await handleRemoveVehicle(data.issue);
          }
        } else {
          setError(RateSourceFields.RateProduct, { message: t('rateAndBilling.invalidRateProduct') });
        }
      } finally {
        setLoadingRateProduct(false);
      }
    },
    [
      bookingEditorId,
      brand,
      defaultEhiDatabase,
      handleCloseModal,
      handleRemoveVehicle,
      setError,
      t,
      updateAndRefresh,
      vehicle,
    ]
  );

  const handleApplyingAccountNumber = useCallback(
    async (values: FieldValues) => {
      setLoadingAccountNumber(true);
      const accountNumberValue = values[RateSourceFields.AccountNumber];
      // Applying with no Account Number: close the modal and apply the best rated retail rates to the reservation
      if (accountNumberValue === EMPTY_VALUE) {
        await handleApplyingRateProduct(values);
        return;
      }
      await refetchAccountDetails()
        .then(async (response: QueryObserverResult<ReservationAccount, EhiErrors>) => {
          const { data: accountDetails, error: accountErrors } = response;
          if (!accountDetails || accountErrors) {
            setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
            return;
          }

          const businessAccount = transformRateSourceInfoFromApi(accountDetails);
          if (businessAccount.urn) {
            await addOrModifyRateSource({ accountNumber: businessAccount.urn, handleCloseModal: handleCloseModal });
          } else {
            setError(RateSourceFields.AccountNumber, { message: t('rateAndBilling.invalidAccountNumber') });
          }
        })
        .catch((error) => {
          handleLogError(
            error,
            `Unable to save account number ${getValues(RateSourceFields.AccountNumber)} to editor id ${bookingEditorId}`
          );
        })
        .finally(() => {
          setLoadingAccountNumber(false);
        });
    },
    [
      addOrModifyRateSource,
      bookingEditorId,
      getValues,
      handleApplyingRateProduct,
      handleCloseModal,
      handleLogError,
      refetchAccountDetails,
      setError,
      t,
    ]
  );

  const handleKeyPress = async (e: any, submitFn: (values: FieldValues) => Promise<void>): Promise<void> => {
    if (e.code === 'Enter' || e.keycode === ENTER_KEY_CODE) {
      const onFormSubmit = handleSubmit(submitFn);
      await onFormSubmit();
    }
  };

  const isAccountNumberApplyButtonDisabled = useCallback((): boolean => {
    const isAccountNumberDefault =
      formState.defaultValues && formState.defaultValues[RateSourceFields.AccountNumber] === accountNumber;
    return isAccountNumberDefault || loadingAccountNumber;
  }, [accountNumber, formState.defaultValues, loadingAccountNumber]);

  return (
    <FormProvider {...formMethods}>
      <Box>
        <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 0)} data-testid='accountNumberContainer'>
          <Grid item>
            <RateAndBillingSearchLabel>{t('rateAndBilling.enterAccountNumber')}</RateAndBillingSearchLabel>
            <StyledFlexGrid spacing={1} data-testid='searchField'>
              <Grid item xs={6} sm={6}>
                <FormTextField
                  name={RateSourceFields.AccountNumber}
                  label={t('rateAndBilling.accountNumber')}
                  data-testid='input'
                  autoFocus={true}
                  fullWidth
                  required
                  onKeyDown={(e: any): Promise<void> => handleKeyPress(e, handleApplyingAccountNumber)}
                  onChange={(e): void => setValue(RateSourceFields.AccountNumber, e.target.value.toUpperCase())}
                  InputProps={{
                    endAdornment: loadingAccountNumber ? (
                      <FieldLoadingIndicator />
                    ) : (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={(): void => {
                          setValue(RateSourceFields.AccountNumber, EMPTY_VALUE);
                          clearErrors(RateSourceFields.AccountNumber);
                        }}
                        disabled={(accountNumber as string)?.length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='applyAccountNumber'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  disabled={isAccountNumberApplyButtonDisabled()}
                  onClick={handleSubmit(handleApplyingAccountNumber)}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </StyledFlexGrid>
          </Grid>
        </RateAndBillingSearchContainer>
        {isOriginalAccount && account && accountDetails && (
          <AccountDetailsContainer>
            <BusinessAccountCard account={accountDetails} titleColor={'black'} />
          </AccountDetailsContainer>
        )}
        {!currentAccountNumber && rateProduct && (
          <AccountDetailsContainer>
            <H6>{t('rateAndBilling.retail')}</H6>
          </AccountDetailsContainer>
        )}
        {isOriginalAccount && brand && (
          <RateAndBillingSearchContainer margin={ehiTheme.spacing(2, 3)} data-testid='rateProductContainer'>
            <RateAndBillingSearchLabel>{t('rateAndBilling.enterRateProduct')}</RateAndBillingSearchLabel>
            <StyledFlexGrid spacing={1} data-testid='rateProductField'>
              <Grid item xs={6} sm={6}>
                <FormTextField
                  name={RateSourceFields.RateProduct}
                  label={t('rateAndBilling.rateProduct')}
                  data-testid='input'
                  fullWidth
                  onChange={(e): void => setValue(RateSourceFields.RateProduct, e.target.value)}
                  onKeyDown={(e: any): Promise<void> => handleKeyPress(e, handleApplyingRateProduct)}
                  InputProps={{
                    endAdornment: loadingRateProduct ? (
                      <FieldLoadingIndicator />
                    ) : (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={(): void => {
                          setValue(RateSourceFields.RateProduct, EMPTY_VALUE);
                          clearErrors(RateSourceFields.RateProduct);
                        }}
                        disabled={(rateProduct as string).length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='applyRateProduct'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  disabled={loadingRateProduct}
                  onClick={handleSubmit(handleApplyingRateProduct)}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </StyledFlexGrid>
          </RateAndBillingSearchContainer>
        )}
      </Box>
    </FormProvider>
  );
};
