import { styled, Tab } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const FlexiAppBar = styled('div')(() => ({
  position: 'sticky',
  width: '100%',
  height: '72px',
  zIndex: '3',
  backGroundColor: `${ehiTheme.palette.background.default}`,
  marginBottom: ehiTheme.spacing(2),
}));

export const FlexiTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  minWidth: '2rem',
  alignSelf: 'baseline',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'fit-content',
}));
