import { DateTime } from 'luxon';

export interface QuickResValues {
  [QuickResFields.CurrentLocationTimezone]: string;
  [QuickResFields.CurrentLocationUrn]: string;
  [QuickResFields.CurrentLocationTime]: DateTime | '';
  [QuickResFields.StartDate]: DateTime | '';
  [QuickResFields.StartTime]: DateTime | '';
  [QuickResFields.StartDateTime]: DateTime | '';
  [QuickResFields.ReturnDate]: DateTime | '';
  [QuickResFields.ReturnTime]: DateTime | '';
  [QuickResFields.ReturnDateTime]: DateTime | '';
  [QuickResFields.AccountNumber]: string;
  [QuickResFields.AccountName]: string;
  [QuickResFields.AccountType]: string;
  [QuickResFields.VehicleClass]: string;
  [QuickResFields.LastName]: string;
  [QuickResFields.FirstName]: string;
  [QuickResFields.Age]: number | string;
  [QuickResFields.ContactType]: string;
  [QuickResFields.CountryCode]: string;
  [QuickResFields.Phone]: string;
  [QuickResFields.Preferences]: string;
}

export enum QuickResFields {
  // Set Location
  CurrentLocationTimezone = 'currentLocationTimezone',
  CurrentLocationUrn = 'currentLocationUrn',
  CurrentLocationTime = 'currentLocationTime',

  // Rental Start
  StartDate = 'startDate',
  StartTime = 'startTime',
  StartDateTime = 'startDateTime',

  // Rental Return
  ReturnDate = 'returnDate',
  ReturnTime = 'returnTime',
  ReturnDateTime = 'returnDateTime',

  // Rate Source
  AccountNumber = 'accountNumber',
  AccountName = 'accountName',
  AccountType = 'accountType',

  // Vehicle
  VehicleClass = 'vehicleClass',

  // Driver
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',

  // Contact
  ContactType = 'contactType',
  CountryCode = 'countryCode',
  Phone = 'phone',

  // Preferences
  Preferences = 'preferences',
}

export type CreateQuickResProps = {
  open: boolean;
  onSaveAndExit: (resNum: string) => void;
  onSaveAndContinue?: (resNum: string) => void;
  onCancel?: () => void;
  onError?: (error: unknown) => void;
};
