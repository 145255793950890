import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { EhiTextField } from 'components/shared/forms/EhiTextField';
import { FormTextFieldProps } from './FormFieldTypes';
import { ReactElement } from 'react';

/**
 * For use within React Hooks forms
 */
export const FormTextField = <T extends FieldValues>({
  name,
  hidden,
  ...props
}: FormTextFieldProps<T>): ReactElement | null => {
  const form = useFormContext<T>();

  if (hidden) {
    return null;
  }
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }): ReactElement => {
        const { ref, ...fieldProps } = field;
        return (
          <EhiTextField
            {...fieldProps}
            fieldRef={ref}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            {...props}
          />
        );
      }}
    />
  );
};
