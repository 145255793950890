import { useCallback, useMemo } from 'react';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectReservationData } from 'redux/selectors/bookingEditor';
import { ReservationAlert, ReservationAlertsHook } from 'components/shared/reservationAlerts/ReservationAlertsTypes';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { TransactionTypes } from 'utils/routing/TransactionTypes';

export const useReservationAlerts = (): ReservationAlertsHook => {
  const { t } = useTranslations();
  const { rateSourceInfo } = useAccountDetails();
  const isViewFlow = useAppSelector(selectIsReadOnlyFlow);
  const { determineTransactionType } = useReservationSessionHelper();
  const reservationData = useAppSelector(selectReservationData);

  const modifyAllowed: boolean = useMemo(() => {
    const transactionType =
      reservationData?.lifecycle &&
      reservationData?.status &&
      determineTransactionType(reservationData.lifecycle, reservationData.status);
    return (
      transactionType === TransactionTypes.Modify &&
      (rateSourceInfo?.reservationConfiguration?.allowModification ||
        rateSourceInfo?.reservationConfiguration?.allowModification === undefined)
    );
  }, [
    determineTransactionType,
    rateSourceInfo?.reservationConfiguration?.allowModification,
    reservationData?.lifecycle,
    reservationData?.status,
  ]);

  const cancelAllowed: boolean = useMemo(() => {
    return (
      rateSourceInfo?.reservationConfiguration?.allowCancel ||
      rateSourceInfo?.reservationConfiguration?.allowCancel === undefined
    );
  }, [rateSourceInfo]);

  const getAlerts: () => Array<ReservationAlert> | [] = useCallback(() => {
    const alerts = [];
    if (!modifyAllowed && isViewFlow) {
      alerts.push({
        title: !cancelAllowed
          ? t('reservationAlerts.unableToModifyOrCancel.title')
          : t('reservationAlerts.unableToModify.title'),
        message: !cancelAllowed
          ? t('reservationAlerts.unableToModifyOrCancel.message')
          : t('reservationAlerts.unableToModify.message'),
      });
    }

    return alerts;
  }, [modifyAllowed, isViewFlow, cancelAllowed, t]);

  return {
    alerts: getAlerts(),
    cancelAllowed,
    modifyAllowed,
  };
};
