import { useDomainQuery } from 'context/queryClient/queryUtil';
import {
  getPhoneTypes,
  getRenterDepartmentTypes,
  getWarningDepartments,
  getWarningReasons,
  retrieveLegalIdentificationType,
} from 'services/renter/renterReferenceService';
import {
  PhoneType,
  DepartmentType,
  WarningReason,
  LegalIdentificationType,
} from 'services/renter/renterReferenceTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { UseQueryResult } from '@tanstack/react-query';
import { WarningDepartment } from 'services/renter/driverProfile/driverProfileTypes';

const RENTER_DEPARTMENT_TYPES_QUERY_KEY = 'renterDepartmentTypes';
const RENTER_WARNING_REASONS_KEY = 'renterWarningReasons';
const RENTER_WARNING_DEPARTMENT_QUERY_KEY = 'renterWarningDepartments';
const RENTER_LEGAL_IDENTIFICATION_QUERY_KEY = 'renterLegalIdentificationType';

export const usePhoneTypesQuery = (): UseQueryResult<PhoneType[], EhiErrors> => {
  return useDomainQuery({ queryKey: 'phoneType', queryFn: () => getPhoneTypes() });
};

export const useGetRenterDepartmentTypes = (): UseQueryResult<DepartmentType[], EhiErrors> => {
  return useDomainQuery({ queryKey: RENTER_DEPARTMENT_TYPES_QUERY_KEY, queryFn: () => getRenterDepartmentTypes() });
};

export const useGetWarningReasons = (): UseQueryResult<WarningReason[], EhiErrors> => {
  return useDomainQuery({ queryKey: RENTER_WARNING_REASONS_KEY, queryFn: () => getWarningReasons() });
};

export const useGetRenterWarningDepartments = (): UseQueryResult<WarningDepartment[], EhiErrors> => {
  return useDomainQuery({ queryKey: RENTER_WARNING_DEPARTMENT_QUERY_KEY, queryFn: () => getWarningDepartments() });
};

export const useGetLegalIdentificationTypes = (): UseQueryResult<LegalIdentificationType[], EhiErrors> => {
  return useDomainQuery({
    queryKey: RENTER_LEGAL_IDENTIFICATION_QUERY_KEY,
    queryFn: () => retrieveLegalIdentificationType(),
  });
};
