import { Box, Grid, styled } from '@mui/material';
import { Body1, Body2, Caption, EhiButton, ehiTheme, Subtitle1 } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const RateAndBillingSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(3)};
`;

export const RateAndBillingLabel = styled(Caption2)`
  display: inline;
`;

export const RateAndBillingText = styled(Body2)`
  display: inline;
  font-weight: bold;
  padding-left: ${ehiTheme.spacing(1)};
`;

export const RateAndBillingInfoBox = styled(Box)`
  display: flex;
  width: 100%;
  background: #f5f5f5;
  padding: ${ehiTheme.spacing(2)};
  margin: ${ehiTheme.spacing(2, 0)};
`;

export const RateSourceLink = styled(Caption, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{
  disabled?: boolean;
}>(({ disabled = false }) => {
  const color = disabled ? '#00000061' : '#4d789d';
  const cursor = disabled ? 'default' : 'pointer';
  return {
    display: 'flex',
    alignItems: 'center',
    cursor,
    color,
  };
});

export const RateAndBillingSearchContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  width: auto;
  padding: ${ehiTheme.spacing(2, 3)};
`;
RateAndBillingSearchContainer.defaultProps = {
  container: true,
};

export const RateAndBillingSearchLabel = styled(Body1)`
  padding-bottom: ${ehiTheme.spacing(2)};
`;

export const StyledFlexGrid = styled(Grid)`
  display: flex;
`;

StyledFlexGrid.defaultProps = {
  container: true,
};

export const AccountDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(0, 3)};
`;

export const AccountListItem = styled('div')`
  padding: ${ehiTheme.spacing(1, 2)};
`;

export const StyledEhiEditButton = styled(EhiButton)`
  padding: ${ehiTheme.spacing(1.5, 1, 1.5, 1)};
  margin-right: ${ehiTheme.spacing(0)};
`;

export const StyledAccountsListBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'containerHeight' && prop !== 'noResultsView',
})<{ containerHeight: number; noResultsView: boolean }>((props) =>
  props.noResultsView
    ? {
        backgroundColor: '#ffffff',
        paddingTop: ehiTheme.spacing(2),
        height: props.containerHeight,
        display: 'flex',
        justifyContent: 'center',
      }
    : {
        backgroundColor: '#ffffff',
        paddingTop: ehiTheme.spacing(2),
        height: props.containerHeight,
        position: 'relative',
      }
);

export const AdditionalInfoEditButton = styled(EhiButton)`
  margin: ${ehiTheme.spacing(0)};
  padding: ${ehiTheme.spacing(0)};
  font-size: 0.875rem;
  margin-top: ${ehiTheme.spacing(0.25)};
  margin-left: ${ehiTheme.spacing(-0.625)};
`;

export const AdditionalInfoCaption = styled(Caption)`
  color: rgba(0, 0, 0, 0.54);
`;

export const AdditionalInfoSubtitle2 = styled(Subtitle1)`
  margin: ${ehiTheme.spacing(0)};
`;
