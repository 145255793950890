import { FC, useCallback, useState } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { useMediaQuery } from '@mui/system';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { BillToAccountNumberSearch } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/BillToAccountNumberSearch';
import { AccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearch';
import { SearchByType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';
import { parseUrn } from 'utils/urnUtils';

export type BillToDialogProps = {
  onClose: () => void;
};
export const EditBillToDialog: FC<BillToDialogProps> = ({ onClose }) => {
  const { t } = useTranslations();
  const [selectedRadioButton, setSelectedRadioButton] = useState<string>(SearchByType.AccountNumber);
  const { setIsAccountNumberFieldHidden, isAccountNumberFieldHidden, setSelectedAccountUrn } = useEditBillToContext();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const handleSelect = useCallback(
    (accountUrn: string) => {
      setIsAccountNumberFieldHidden(true);
      setSelectedAccountUrn(parseUrn(accountUrn));
    },
    [setIsAccountNumberFieldHidden, setSelectedAccountUrn]
  );

  const onBackClicked = useCallback(() => {
    setIsAccountNumberFieldHidden(false);
    setSelectedAccountUrn(undefined);
  }, [setIsAccountNumberFieldHidden, setSelectedAccountUrn]);

  return (
    <Dialog
      PaperProps={{
        sx: {
          height: '95%',
          width: isMobileOrTablet ? '94%' : '52%',
        },
      }}
      a11yKey={'edit-bill-to'}
      data-testid='editBillToDialog'
      open={true}
      fullScreen
      title={t('rateAndBilling.billTo.billToDialogTitle')}
      showDividers
      contentPadding={0}
      actions={{
        primaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
      }}>
      {!isAccountNumberFieldHidden && (
        <RadioGroup
          row
          style={{ padding: ehiTheme.spacing(2, 3) }}
          value={selectedRadioButton}
          onChange={(e): void => {
            if (e.currentTarget.value !== selectedRadioButton) {
              setSelectedRadioButton(e.currentTarget.value);
            }
          }}>
          <FormControlLabel
            value={SearchByType.AccountNumber}
            checked={selectedRadioButton === SearchByType.AccountNumber}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountNumber')}
          />
          <FormControlLabel
            value={SearchByType.AccountSearch}
            checked={selectedRadioButton === SearchByType.AccountSearch}
            control={<Radio color='primary' />}
            label={t('rateAndBilling.accountSearch')}
          />
        </RadioGroup>
      )}
      {selectedRadioButton === SearchByType.AccountNumber || isAccountNumberFieldHidden ? (
        <BillToAccountNumberSearch handleCloseModal={onClose} onBackClicked={onBackClicked} />
      ) : (
        <AccountSearch handleSelect={handleSelect} />
      )}
    </Dialog>
  );
};
