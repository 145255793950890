import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslations } from 'components/shared/i18n';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { EMPTY_VALUE } from 'utils/constants';
import { FC } from 'react';
import { Body2, ehiTheme } from '@ehi/ui';

export type SnackbarProps = {
  message: string;
  onViewAction?: () => void;
  isOpen: boolean;
};
const autoHideDuration = 8000;

export const ReservationSnackBar: FC<SnackbarProps> = ({ message, onViewAction, isOpen }) => {
  const { setSnackBarRes } = useResSnackbarContext();
  const { t } = useTranslations();

  const handleClose = (): void => {
    setSnackBarRes({ message: EMPTY_VALUE, isOpen: false });
  };

  const action = (
    <>
      {onViewAction && (
        <Button
          data-testid={'viewButton'}
          color='secondary'
          sx={{ paddingTop: ehiTheme.spacing(0.5), paddingBottom: ehiTheme.spacing(0.5) }}
          onClick={(): void => {
            onViewAction();
            handleClose();
          }}>
          {t('common.view')}
        </Button>
      )}

      <IconButton
        sx={{ paddingTop: ehiTheme.spacing(0.5), paddingBottom: ehiTheme.spacing(0.5) }}
        aria-label='close'
        color='inherit'
        onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      data-testid={'snackBar'}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      message={<Body2>{message}</Body2>}
      action={action}
      sx={{
        '& .MuiSnackbarContent-root': {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
        '& .MuiSnackbarContent-message': {
          flex: 1,
        },
      }}
    />
  );
};
