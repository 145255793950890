import { useEffect, useRef } from 'react';

/**
 * Used when you want to do some process on mount, and ONLY on mount.
 * Runs the given function only once in strict mode.
 */
export const useEffectOnlyOnce = (fn: () => any): void => {
  const called = useRef(false);

  useEffect(() => {
    if (called.current) {
      return;
    }

    called.current = true;
    fn();
    // this hook will only ever run once; no need to check dependencies!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
