import {
  AddressV2,
  BranchHierarchy,
  BranchOperationalId,
  BranchPhase,
  BranchV2,
  FulfillmentBookingSystems,
} from 'services/location/locationTypes';
import { COUNTRY_CODE_CA, COUNTRY_CODE_US, EMPTY_VALUE } from 'utils/constants';
import { Location } from 'services/location/locationRollupTypes';

const branchTypes = ['DAILY RENTAL', 'TRUCK RENTAL'];

export const isAllowedBranch = (branch: BranchV2): boolean => {
  return (
    isOpenBranch(branch) &&
    (isPrimaryBranch(branch) || isODYSSEYOnlyBranch(branch)) &&
    !isLicenseeBranch(branch) &&
    isDailyOrTruckRental(branch)
  );
};

export const isOpenBranch = (branch: BranchV2): boolean => {
  return BranchPhase.OPEN === branch.branchPhase;
};

export const isDailyOrTruckRental = (branch: BranchV2): boolean => {
  return branchTypes.includes(branch.type);
};

/*
     Primary branches are determined in LRD when multiple branches share the same station id. This is only
     applicable for stations that are not ECARS only.
     */
export const isPrimaryBranch = (branch: BranchV2): boolean => {
  return branch.primaryStation ?? false;
};

/*
      ECARS only branches do not follow the primary branch pattern since it's not a business use case. Instead,
      ECARS "primary" branches are determined if their fulfillment system is ECARS not Odyseey when multiple stations
      share the same station id.
     */

export const isECARSOnlyBranch = (branch: BranchV2): boolean => {
  return branch.operationalModel?.rentalConfiguration?.fulfillmentSystem === FulfillmentBookingSystems.ECARS;
};

export const isODYSSEYOnlyBranch = (branch: BranchV2): boolean => {
  return branch.operationalModel?.rentalConfiguration?.fulfillmentSystem === FulfillmentBookingSystems.ODYSSEY;
};

/*
      Licensee branches are 3rd party locations that are licenced out. When rolling up to the area these locations
      should be filtered out because EHI owned locations should not be able to see competition.
     */
export const isLicenseeBranch = (branch: BranchV2): boolean => {
  return branch.licensee;
};

/*
    Will parse the group out of a branch or area operational id string or return an empty string if the id is smaller than 2.
  */
export const parseGroup = (id: BranchOperationalId): string => {
  if (id.length < 2) return '';
  return id.slice(0, 2);
};

export const extractCountryCode = (addresses: AddressV2[] | undefined): string => {
  if (addresses !== undefined && addresses[0] !== undefined && addresses[0].country !== undefined) {
    return addresses[0].country.code ?? '';
  }
  return '';
};

export function filterBranches(branches: BranchV2[]): BranchV2[] {
  return branches.filter((branch) => isAllowedBranch(branch));
}

export const isOneWay = (branch1: string | undefined, branch2: string | undefined): boolean => {
  return branch1 !== branch2;
};

export const isNaCountryCode = (countryCode?: string): boolean => {
  return !!countryCode && (countryCode === COUNTRY_CODE_US || countryCode === COUNTRY_CODE_CA);
};

export const getDefaultCountry = (locationQuery?: Location): string => {
  return locationQuery?.location?.countryCode ?? COUNTRY_CODE_US;
};

export const getBranchCountry = (branchHierarchy?: BranchHierarchy): string => {
  return branchHierarchy?.branch.countryCode ?? COUNTRY_CODE_US;
};

export const getBranchGroup = (branchHierarchy?: BranchHierarchy): string => {
  return branchHierarchy?.group?.urn ?? EMPTY_VALUE;
};
