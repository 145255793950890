import { parseUrn } from 'utils/urnUtils';
import {
  AdditionalInformationBase,
  ReservationAccount,
  ReservationConfiguration,
} from 'services/businessAccount/businessAccountTypes';

export type RateAndBillingInfo = {
  urn?: string;
  number?: string;
  name?: string;
  type?: string;
  reservationConfiguration?: ReservationConfiguration;
};
import { AdditionalInfoAccountType } from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';

export const transformRateSourceInfoFromApi = (accountDetails: ReservationAccount): RateAndBillingInfo => {
  return {
    urn: accountDetails.urn,
    number: parseUrn(accountDetails.urn),
    name: accountDetails.name,
    type: parseUrn(accountDetails.type),
    reservationConfiguration: accountDetails.reservationConfiguration,
  };
};

export const transformAdditionalInformationInfoFromApi = (
  additionalInfo: AdditionalInformationBase[],
  accountType: AdditionalInfoAccountType
): AdditionalInformationBase[] => {
  if (!Array.isArray(additionalInfo)) return [];

  const filteredFields =
    accountType === AdditionalInfoAccountType.RATE_SOURCE
      ? additionalInfo.filter((field) => !field.onlyForBusinessPayer)
      : additionalInfo.filter((field) => field.onlyForBusinessPayer);

  return filteredFields;
};
