import { DateTime } from 'luxon';
import { TranslationKey } from 'components/shared/i18n/i18n';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { LoyaltyMembershipSearchResult } from 'services/renter/driverProfile/driverProfileTypes';

export type DriverSearchProps = {
  navigateToDriverForm: (driver: DriverData | undefined) => void;
  onClose: () => void;
  isUpdatingPrimaryDriver?: boolean;
};

export class SearchByType {
  static readonly RenterInformation = new SearchByType('RenterInformation', 'driver.renterInformation');
  static readonly LoyaltyNumber = new SearchByType('LoyaltyNumber', 'driver.loyaltyNumber');
  static readonly DriversLicense = new SearchByType('DriversLicense', 'driver.driversLicense');

  private constructor(private readonly key: string, public readonly value: TranslationKey) {}

  toString(): string {
    return this.key;
  }
}

export interface DriverSearchValues {
  searchBy?: SearchByType;
  loyaltyNumber: string;
  licenseNumber: string;
  country: string;
  state: string | undefined;
  lastName: string;
  firstName: string;
  dateOfBirth: DateTime | undefined;
  phoneNumber: string;
}

export enum DriverSearchFields {
  SearchBy = 'searchBy',
  LoyaltyNumber = 'loyaltyNumber',
  LicenseNumber = 'licenseNumber',
  Country = 'country',
  State = 'state',
  LastName = 'lastName',
  FirstName = 'firstName',
  DOB = 'dateOfBirth',
  PhoneNumber = 'phoneNumber',
}

export interface SelectedDriverProfile {
  urn: string | undefined;
  loyaltyMembership: LoyaltyMembershipSearchResult | undefined;
  accountDetails: ReservationAccount | undefined;
}
