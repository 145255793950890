import { useState, FC, useMemo } from 'react';
import { Body1, ehiTheme } from '@ehi/ui';
import { Box, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslations } from 'components/shared/i18n';
import {
  AdditionalInfoCaption,
  AdditionalInfoEditButton,
  AdditionalInfoSubtitle2,
} from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { DOUBLE_DASH } from 'utils/constants';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { AdditionalInfoData } from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { EditAdditionalInfoDialog } from './additionalInformation/EditAdditionalInfoDialog';

type AdditionalInfoProps = {
  additionalInfo: AdditionalInfoData;
};

export const AdditionalInformationSection: FC<AdditionalInfoProps> = ({ additionalInfo }) => {
  const { t } = useTranslations();
  const [editAdditionalInfo, setEditAdditionalInfo] = useState<boolean>(false);
  const showCaret = useMemo(
    () => additionalInfo?.additionalInfoFields?.length > 3,
    [additionalInfo?.additionalInfoFields]
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(showCaret);
  const isReadOnly = useAppSelector(selectIsReadOnlyFlow);

  const handleOnClick = (): void => {
    setEditAdditionalInfo(true);
  };
  const toggleExpand = (): void => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Box display='flex' flexDirection='column' data-testid='additional-information-container'>
      <Grid container display={'flex'} alignItems={'center'}>
        <Grid item xs={9} sm={9}>
          <Box display='flex' alignItems='center' data-testid='additional-info-header'>
            <AdditionalInfoSubtitle2 bold data-testid='additional-info-title'>
              {t('rateAndBilling.additionalInformation')}
            </AdditionalInfoSubtitle2>
            {!isReadOnly && (
              <AdditionalInfoEditButton size='small' variant='text' onClick={handleOnClick} data-testid='edit-button'>
                {t('common.edit')}
              </AdditionalInfoEditButton>
            )}
          </Box>
        </Grid>
        {showCaret && (
          <Grid item xs={3} sm={3} display={'flex'} justifyContent={'flex-end'}>
            <IconButton data-testid='toggle-expand-button' onClick={toggleExpand}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Box style={{ margin: ehiTheme.spacing(1.2, 0) }}>
        <Grid container spacing={2} data-testid='additional-info-fields'>
          {additionalInfo?.additionalInfoFields
            ?.slice(0, showCaret && !isExpanded ? 3 : additionalInfo?.additionalInfoFields?.length)
            .map((field) => (
              <Grid item xs={12} sm={4} key={field.fieldId} data-testid={`info-field-${field.fieldId}`}>
                <Box>
                  <AdditionalInfoCaption data-testid={`info-caption-${field.fieldId}`}>
                    {field?.fieldName}
                    {field.isRequiredAtReservation && <span>*</span>}
                  </AdditionalInfoCaption>
                  <Body1 data-testid={`info-helper-text-${field.fieldId}`}>
                    {field.fieldValue ? field.fieldValue : DOUBLE_DASH}
                  </Body1>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
      {editAdditionalInfo && (
        <EditAdditionalInfoDialog
          additionalInfoData={additionalInfo}
          open={editAdditionalInfo}
          onClose={(): void => setEditAdditionalInfo(false)}
        />
      )}
    </Box>
  );
};
