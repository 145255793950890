import { useAttachmentContext } from '@ehi/support';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ErrorSeverity } from '@ehi/analytics';
import { logError } from 'components/shared/logger/splunkLogger';
import { RouterPaths } from 'app/router/RouterPaths';

export type SupportHook = {
  goToSupport: (cleanup?: () => void, collectErrorInformation?: () => string) => void;
};

export const useSupport = (): SupportHook => {
  const { generateScreenshot } = useAttachmentContext();
  const navigate = useNavigate();

  const goToSupport = async (cleanup?: () => void, collectErrorInformation?: () => string): Promise<void> => {
    try {
      await generateScreenshot();
      cleanup?.();
    } catch (err) {
      logError({
        error: { message: 'Error generating Screenshot ', supportInformation: err },
        severity: ErrorSeverity.Warning,
      });
    }

    navigate({
      pathname: RouterPaths.SupportTicket,
      search: createSearchParams({
        returnUrl: window.location.href,
        errorInfo: collectErrorInformation?.() || '',
      }).toString(),
    });
  };

  return { goToSupport };
};
