import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { VehicleAutoCompleteField } from 'components/shared/forms/VehicleAutoCompleteField';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectBookingEditorIssues, selectHasDatetimeData } from 'redux/selectors/bookingEditor';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { useFormContext } from 'react-hook-form';
import { deleteVehicleClassSelection, updateVehicleClassSelection } from 'services/booking/bookingService';
import { EHI_DOMAINS, generateReferenceUrn, parseUrn } from 'utils/urnUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { EMPTY_VALUE } from 'utils/constants';
import { getDistanceAllotmentString, getRateString } from 'components/quickRes/quickResUtils';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { SectionContainer, SectionInfo, VehicleErrorMessage } from 'components/quickRes/QuickRes.styles';
import { GridItem } from 'components/shared/ui/styles/Grid.styles';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import InfoIcon from '@mui/icons-material/Info';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logDebug } from 'utils/logUtils';
import { VehicleAvailabilityBookingIssues } from 'utils/bookingUtils';

const QuickResVehicle: FC = () => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const appConfig = getAppConfigCache();
  const defaultEhiDatabase = appConfig?.defaultEhiDatabase ?? '';
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const hasDateTime = useAppSelector(selectHasDatetimeData);
  const [isVehicleUpdating, setIsVehicleUpdating] = useState<boolean>(false);
  const { setValue, setError, clearErrors, watch } = useFormContext();
  const [carClass] = watch([QuickResFields.VehicleClass]);
  const { data: vehicleContent, isFetching: isVehicleContentLoading } = useVehicleContentQuery();
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();
  const isLoading = isVehicleRatesLoading || isVehicleContentLoading;
  const bookingEditorIssues = useAppSelector(selectBookingEditorIssues);
  const { showAlert } = useAlert();

  const vehicles = useMemo(
    () => transformVehicleListFromApiData(vehicleContent, vehicleRates),
    [vehicleRates, vehicleContent]
  );

  const vehicleRateDetails =
    vehicles?.length && vehicleRates?.length ? vehicles.filter((item) => item.vehicleClass === carClass)[0] : undefined;

  const rateString = useMemo(() => {
    return vehicleRateDetails ? getRateString(vehicleRateDetails, t, locale) : '';
  }, [vehicleRateDetails, t, locale]);

  const distanceAllotmentString = useMemo(() => {
    return vehicleRateDetails ? getDistanceAllotmentString(vehicleRateDetails, t) : '';
  }, [vehicleRateDetails, t]);

  const hasVehicleInfo = !!rateString || !!distanceAllotmentString;

  const updateVehicleClass = useCallback(
    async (vehicleToAdd: string | undefined, clearError: boolean) => {
      setIsVehicleUpdating(true);

      try {
        const callToUpdateVehicle = !vehicleToAdd
          ? await deleteVehicleClassSelection(bookingEditorId)
          : await updateVehicleClassSelection(bookingEditorId, {
              type: 'PAYLATER',
              vehicleClass: generateReferenceUrn(
                EHI_DOMAINS.vehicle.name,
                EHI_DOMAINS.vehicle.vehicleClass,
                vehicleToAdd,
                defaultEhiDatabase
              ),
            });
        const { errors } = await updateAndRefresh(async () => callToUpdateVehicle);
        if (errors) {
          await showAlert({
            variant: 'error',
            description: errors?.[0]?.localizedMessage ?? '',
            primaryActionText: 'dismiss',
          });
        } else {
          // Adding a check for clear error to keep error message
          // when there is an error code 2053 returned from the editor
          clearError && clearErrors(QuickResFields.VehicleClass);
          setValue(QuickResFields.VehicleClass, vehicleToAdd ? vehicleToAdd : EMPTY_VALUE);
          document.getElementsByName(QuickResFields.VehicleClass).item(0).blur();
        }
      } catch (error) {
        logDebug(error);
      } finally {
        setIsVehicleUpdating(false);
      }
    },
    [bookingEditorId, clearErrors, defaultEhiDatabase, setValue, showAlert, updateAndRefresh]
  );

  const handleVehicleUnAvailability = useCallback(async () => {
    setError(QuickResFields.VehicleClass, {
      message: (
        <VehicleErrorMessage>
          <InfoIcon sx={{ paddingRight: ehiTheme.spacing(1) }} />
          {t('vehicle.vehicleIsNotAvail')}
        </VehicleErrorMessage>
      ) as any,
    });
    await updateVehicleClass(undefined, false);
  }, [t, setError, updateVehicleClass]);

  useEffect(() => {
    if (bookingEditorIssues && bookingEditorIssues?.length > 0 && carClass && hasDateTime) {
      const vehicleErrorCode = bookingEditorIssues.find(
        (issue) => parseUrn(issue.issueCode) === VehicleAvailabilityBookingIssues.PreferredVehicleNotAvailable
      );
      if (vehicleErrorCode) {
        handleVehicleUnAvailability();
      }
    }
  }, [bookingEditorIssues, handleVehicleUnAvailability, carClass, hasDateTime]);

  return (
    <SectionContainer>
      <Subtitle1 bold data-testid={'res-vehicle'}>
        {t('common.vehicle')}
      </Subtitle1>
      <GridItem sm={12} style={{ padding: ehiTheme.spacing(2, 0) }}>
        <VehicleAutoCompleteField
          vehicles={vehicles}
          isLoading={isLoading}
          selectedVehicleClass={carClass}
          setVehicle={(vehicle): Promise<void> => updateVehicleClass(vehicle, true)}
          resetVehicle={(): Promise<void> => updateVehicleClass(undefined, true)}
        />
      </GridItem>
      {hasVehicleInfo && !isLoading && (
        <SectionInfo data-testid={'vehicleRateSection'} sx={{ justifyContent: 'flex-end' }}>
          {rateString && (
            <VehicleInfoColumn dataTestid={'vehicleRateInfo'} label={t('vehicle.currentRate')} data={rateString} />
          )}
          <VehicleInfoColumn
            dataTestid={'distanceAllotmentInfo'}
            label={t('vehicle.distanceAllotment')}
            data={distanceAllotmentString}
          />
        </SectionInfo>
      )}
      <ProgressOverlay inProgress={isVehicleUpdating} />
    </SectionContainer>
  );
};
export default QuickResVehicle;

const VehicleInfoColumn = ({
  dataTestid,
  label,
  data,
}: {
  dataTestid: string;
  label: string;
  data: string;
}): ReactElement => {
  return (
    <Grid item xs={6} sm={6} data-testid={dataTestid}>
      <Caption2 sx={{ color: '#0000008A' }}>{label}</Caption2>
      <Body2 bold marginTop={ehiTheme.spacing(0.5)}>
        {data}
      </Body2>
    </Grid>
  );
};
