import { FC, ReactElement, useState } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslations } from 'components/shared/i18n';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { H6 } from '@ehi/ui';
import {
  Container,
  LabelContainer,
  Placeholder,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledSelectWrapper,
} from 'components/flexFlow/driver/additionalDriver/QuickDriverDialog.styles';
import { useAppSelector } from 'redux/hooks';
import { selectAdditionalDrivers, selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { updateAdditionalDrivers } from 'services/booking/bookingService';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { ErrorSeverity } from '@ehi/analytics';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { AdditionalDriver } from 'services/booking/bookingTypes';
import { addAdditionalDriverError } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';

interface QuickDriverDialogProps {
  open: boolean;
  onClose: () => void;
  driverCount: number;
}

export const QuickDriverDialog: FC<QuickDriverDialogProps> = ({ open, onClose, driverCount = 0 }) => {
  const [numDrivers, setNumDrivers] = useState<string>('');
  const { t } = useTranslations();
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { showAlert } = useAlert();
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const [loading, setLoading] = useState(false);

  const handleNumDriversChange = (event: SelectChangeEvent<unknown>): void => {
    setNumDrivers(() => event.target.value as string);
  };

  const handleLogError = (error: unknown, message: string): void => {
    logError({
      error: {
        message: message,
        supportInformation: {
          transactionType: TransactionTypes.CreateFullRes,
          location: cookieLocation,
          counter: counterCookie?.counterId,
          serviceError: error,
        },
      },
      severity: ErrorSeverity.Fatal,
    });
    setLoading(false);
  };
  const onApplyClick = async (): Promise<void> => {
    try {
      const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
      const noProfileDrivers: AdditionalDriver[] = [];
      for (let i = 1; i <= Number(numDrivers); i++) {
        noProfileDrivers.push({});
      }

      const { errors } = await updateAndRefresh(() =>
        updateAdditionalDrivers(bookingEditorId, currentAdditionalDrivers.concat(noProfileDrivers))
      );

      if (errors) {
        setLoading(false);
        await showAlert({
          title: t('error.error'),
          description: `${t('driver.addAdditionalDriverError')}: ${errors?.[0]?.localizedMessage || ''}`,
        });
      } else {
        onClose();
      }
    } catch (error) {
      handleLogError(error, addAdditionalDriverError);
    }
  };

  const options = [];
  for (let i = 1; i <= 9 - driverCount; i++) {
    options.push({ label: i.toString(), value: i.toString() });
  }

  return (
    <Dialog
      data-testid='quick-add-driver-dialog'
      contentPadding={0}
      open={open}
      title={t('driver.additionalDrivers.addAdditionalDrivers')}
      a11yKey='content'
      maxWidth={'sm'}
      fullWidth
      actions={{
        secondaryAction: {
          label: t('common.cancel'),
          onClick: onClose,
        },
        primaryAction: {
          label: t('driver.additionalDrivers.addDrivers'),
          onClick: onApplyClick,
          overrideButtonProps: {
            disabled: !numDrivers,
          },
        },
      }}>
      <Container data-testid='dialog-container'>
        <H6 data-testid='dialog-title'>{t('driver.additionalDrivers.additionalDrivers')}</H6>
        <LabelContainer data-testid='label-container'>
          {numDrivers && (
            <StyledInputLabel htmlFor='num-drivers' data-testid='input-label'>
              {t('driver.additionalDrivers.numberDrivers')}
            </StyledInputLabel>
          )}

          <StyledSelectWrapper data-testid='select-wrapper'>
            <StyledSelect
              labelId='num-drivers-label'
              id='num-drivers'
              value={numDrivers}
              onChange={handleNumDriversChange}
              displayEmpty
              renderValue={(selected): ReactElement | string => {
                if (selected === '') {
                  return (
                    <Placeholder data-testid='placeholder'>{t('driver.additionalDrivers.numberDrivers')}</Placeholder>
                  );
                }
                return selected as string;
              }}
              data-testid='quickDriverSelect'>
              {options.map((option) => (
                <StyledMenuItem key={option.value} value={option.value} data-testid={`menu-item-${option.value}`}>
                  {option.label}
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </StyledSelectWrapper>
        </LabelContainer>
      </Container>
      <ProgressOverlay inProgress={loading} />
    </Dialog>
  );
};
