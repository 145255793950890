import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { FieldLoadingIndicator } from './FieldLoadingIndicator';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { Subtitle1 } from '@ehi/ui';

export type AutoCompleteOption = {
  key: string;
  title: string;
};

export type AutoCompleteFieldProps = {
  name: string;
  label?: string;
  options: AutoCompleteOption[];
  loading?: boolean;
  handleOnChange: (value: AutoCompleteOption) => void;
  value: AutoCompleteOption;
  handleRemoveOption?: () => void;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: ReactNode;
  color?: string;
};

export const AutoCompleteField: FC<AutoCompleteFieldProps> = ({
  options,
  name,
  label,
  loading,
  handleOnChange,
  value,
  handleRemoveOption,
  disabled,
  placeholder,
  startAdornment,
  color,
}: AutoCompleteFieldProps) => {
  const form = useFormContext();
  const optionsList = useMemo(() => {
    return [{ key: '', title: '' }, ...options];
  }, [options]);

  const getEndAdornment = (params: AutocompleteRenderInputParams): ReactElement => {
    if (loading) {
      return <FieldLoadingIndicator />;
    } else {
      return (
        <div>
          {handleRemoveOption && value.title && (
            <InputIconButton icon={<FieldClearIcon />} onClick={handleRemoveOption} />
          )}
          {params.InputProps.endAdornment}
        </div>
      );
    }
  };

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState }): ReactElement => (
        <Autocomplete
          {...field}
          id='form-auto-complete'
          data-testid={`${name}=auto-complete`}
          value={value}
          isOptionEqualToValue={(option, value): boolean => {
            return value.title === option.title;
          }}
          disabled={disabled}
          disableClearable
          options={optionsList}
          getOptionLabel={(option): string => option.title}
          getOptionKey={(option): string => option.key}
          loading={loading}
          autoHighlight
          onChange={(_event, value: AutoCompleteOption): void => {
            handleOnChange(value);
          }}
          renderOption={(props, option): ReactElement | undefined => {
            const { key, color: defaultColor, ...optionProps } = props;
            return option.key !== '' ? (
              <Subtitle1 key={key} {...optionProps} color={color ?? defaultColor}>
                {option.title}
              </Subtitle1>
            ) : undefined;
          }}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              label={label && label}
              placeholder={placeholder && placeholder}
              error={!!fieldState.error?.message}
              helperText={fieldState.error?.message}
              InputProps={{
                ...params.InputProps,
                startAdornment: startAdornment && startAdornment,
                endAdornment: getEndAdornment(params),
              }}
            />
          )}
        />
      )}
    />
  );
};
