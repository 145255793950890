import { Body1, ehiTheme, H4, H6 } from '@ehi/ui';
import { NoResultsContainer } from 'components/shared/ui/noResultsView/NoResultsViewStyles';
import { FC, ReactNode } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { TranslationKey } from 'components/shared/i18n/i18n';

type NoResultsViewType = {
  noResultsTitle: TranslationKey;
  noResultsDescription: ReactNode;
  isBackgroundColor?: boolean;
  // uses to differentiate page vs sub page title
  // notes: Branch lookup modal has bigger font vs sub pages like Vehicle, Driver, PEO
  pageTitle?: boolean;
};

export const NoResultsView: FC<NoResultsViewType> = ({
  noResultsTitle,
  noResultsDescription,
  isBackgroundColor = true,
  pageTitle,
}: NoResultsViewType) => {
  const { t } = useTranslations();
  return (
    <NoResultsContainer className={isBackgroundColor ? 'isBackgroundColor' : ''}>
      {pageTitle ? (
        <H4 color={ehiTheme.palette.text.disabled} data-testid={'noResultsTitle'}>
          {t(noResultsTitle)}
        </H4>
      ) : (
        <H6 color={ehiTheme.palette.text.disabled} data-testid={'noResultsTitle'}>
          {t(noResultsTitle)}
        </H6>
      )}
      {noResultsDescription && (
        <Body1 data-testid={'noResultsDescription'} padding={ehiTheme.spacing(1, 2, 4, 0)} color='textSecondary'>
          {noResultsDescription}
        </Body1>
      )}
    </NoResultsContainer>
  );
};
