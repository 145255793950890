import { styled, TableCell } from '@mui/material';
import { Body1 } from '@ehi/ui';

export const RowItemLabel = styled(Body1)`
  color: #000000;
  font-weight: bold;
`;

export const RowItem = styled(TableCell)`
  border-bottom: 1px solid #0000003b;
`;

export const RowItemText = styled(Body1)`
  color: #000000;
`;
