import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId, selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useCallback } from 'react';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { RateSource } from 'services/booking/bookingTypes';
import { modifyRateSource } from 'services/booking/bookingService';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { ACCOUNT_TYPE_NEGOTIATED } from 'utils/rateAndBillingUtils';
import { getAllVehicleNotAvailableBookingIssues, hasVehicleAvailabilityBookingIssues } from 'utils/bookingUtils';
import { useTranslations } from 'components/shared/i18n';

export type AddOrModifyRateSourceProps = {
  accountNumber: string;
  handleCloseModal: () => void;
};

export type useRateAndBillingHookReturn = {
  addOrModifyRateSource: (props: AddOrModifyRateSourceProps) => Promise<void>;
};

export const useRateSource = (): useRateAndBillingHookReturn => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { handleRemoveVehicle } = useBookingIssue();

  const addOrModifyRateSource = useCallback(
    async ({ accountNumber, handleCloseModal }: AddOrModifyRateSourceProps): Promise<void> => {
      const rateSourceRequestBody: RateSource = {
        type: ACCOUNT_TYPE_NEGOTIATED,
        account: accountNumber,
      };
      const { data, errors } = await updateAndRefresh(() => modifyRateSource(bookingEditorId, rateSourceRequestBody), {
        availableBookingIssues: [
          ...getAllVehicleNotAvailableBookingIssues(t, t('snackbarMessages.additionalInfo.account'), vehicle),
        ],
      });

      if (!errors) {
        handleCloseModal();
        if (data?.issue && hasVehicleAvailabilityBookingIssues(data.issue)) {
          await handleRemoveVehicle(data.issue);
        }
      } else {
        await showAlert({
          variant: 'error',
          description: errors?.[0]?.localizedMessage || '',
        });
      }
    },
    [updateAndRefresh, t, vehicle, bookingEditorId, handleRemoveVehicle, showAlert]
  );

  return {
    addOrModifyRateSource,
  };
};
