import { enUS, frCA } from '@ehi/location';
import { Countries, Country, CountrySubdivision } from 'services/location/locationTypes';

export enum CorporateCountry {
  UnitedStates = 'USA',
  Canada = 'CAN',
  GreatBritain = 'GBR',
  Ireland = 'IRL',
  Spain = 'ESP',
  Germany = 'DEU',
  France = 'FRA',
}

export const CORPORATE_COUNTRIES = Object.values(CorporateCountry);

export function isNACountry(countryIso3: string): boolean {
  return countryIso3 === enUS.iso3Country || countryIso3 === frCA.iso3Country;
}

export const getCountry = (countries: Countries | undefined, iso3code: string | undefined): Country | undefined => {
  return countries?.find((value) => value.countryIso3Code === iso3code);
};

export const getSubdivision = (
  subdivisions: CountrySubdivision[] | undefined,
  subdivisionUrn: string | undefined
): CountrySubdivision | undefined => {
  return subdivisions?.find((value) => value.urn === subdivisionUrn);
};
