import { Box, Button, styled } from '@mui/material';
import { Body1, ehiTheme } from '@ehi/ui';

export const ActionableAlertMessageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const AlertDescriptionContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${ehiTheme.spacing(1, 0)};
`;

export const AlertDescriptionText = styled(Body1)`
  text-align: left;
  width: 100%;
`;

export const ActionableAlertButton = styled(Button)`
  display: flex;
  margin: 0;
  padding: ${ehiTheme.spacing(1)};
`;
