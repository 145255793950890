import { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { useTranslations } from 'components/shared/i18n';
import { DriverForm } from 'components/flexFlow/driver/driverForm/DriverForm';
import { EhiButton } from '@ehi/ui';
import { ContentContainer } from 'components/flexFlow/driver/Driver.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { ADD, REMOVE } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { associateRenterToReservationEditor, updateAdditionalDrivers } from 'services/booking/bookingService';
import { Renter } from 'services/booking/bookingTypes';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAlert } from 'components/shared/alert/AlertContext';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { addAdditionalDriverError } from 'components/flexFlow/driver/driverSearch/driverSearchUtils';
import { logError } from 'components/shared/logger/splunkLogger';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { useAppSelector } from 'redux/hooks';
import { useDriverLicenseValidation } from 'hooks/driverLicenseValidation/useDriverLicenseValidation';

export type EditDriverDialogProps = {
  driver: DriverData | undefined;
  open: boolean;
  onClose: (driverModified: boolean) => void;
  isAdditionalDriver: boolean;
  changePrimaryDriverDialogIsOpen: boolean;
  setChangePrimaryDriverDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const EditDriverDialog: FC<EditDriverDialogProps> = ({
  open,
  onClose,
  driver,
  isAdditionalDriver,
  changePrimaryDriverDialogIsOpen,
  setChangePrimaryDriverDialogIsOpen,
}) => {
  const { t } = useTranslations();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);
  const navigate = useNavigate();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const [primaryDriverConfirmationOpen, setPrimaryDriverConfirmationOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const currentAdditionalDrivers = useMemo(
    () => (additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : []),
    [additionalDrivers]
  );
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const { validateExpirationDateAgainstRentalDates } = useDriverLicenseValidation();

  const submitForm = async (): Promise<void> => {
    if (formRef.current) {
      await formRef.current.handleSubmit();
    }
  };

  const handleClose = useCallback(
    (driverModified: boolean) => {
      onClose(driverModified);
    },
    [onClose]
  );

  const handleChangePrimaryDriverClick = (): void => {
    if (isAdditionalDriver) {
      setPrimaryDriverConfirmationOpen(true);
    } else {
      navigate({ hash: HashPaths.ReplaceDriver }, { replace: true });
      handleClose(false);
    }
  };

  const updateWithErrorHandling = async (updateFunc: () => Promise<any>): Promise<void> => {
    const { errors } = await updateAndRefresh(async () => {
      await updateFunc();
    });
    if (errors) {
      setLoading(false);
      await showAlert({ responseMessages: errors });
    } else {
      onClose(true);
    }
  };

  const createPrimaryDriverRequestBody = (driver: DriverData): Renter | undefined => {
    if (driver.type === RenterTypes.DriverProfile) {
      return {
        type: driver.type,
        profile: driver.urn,
        membership: driver.loyaltyProgram?.urn ? { loyaltyProgram: driver.loyaltyProgram?.urn } : undefined,
      } as Renter;
    } else if (driver.type === RenterTypes.TransactionalProfile) {
      return {
        type: driver.type,
        profile: driver.urn,
      } as Renter;
    }
  };

  const handleConfirmChangePrimaryDriver = async (selection: string): Promise<void> => {
    setPrimaryDriverConfirmationOpen(false);

    if (driver) {
      try {
        setLoading(true);

        await updateWithErrorHandling(() =>
          associateRenterToReservationEditor(bookingEditorId, createPrimaryDriverRequestBody(driver) as Renter)
        );

        const currentAdditionalDrivers = additionalDrivers?.length ? additionalDrivers : [];
        const updatedAdditionalDrivers = currentAdditionalDrivers.filter(
          (additionalDriver) => additionalDriver.profile !== driver.urn
        );
        if (selection !== REMOVE) {
          updatedAdditionalDrivers.push({
            profile: driverProfileRenter?.profile,
          });
        }
        await updateWithErrorHandling(() => updateAdditionalDrivers(bookingEditorId, updatedAdditionalDrivers));

        driver.legalIdentification?.expirationDate &&
          validateExpirationDateAgainstRentalDates(driver.legalIdentification?.expirationDate);

        handleClose(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelChangePrimaryDriver = (): void => {
    setPrimaryDriverConfirmationOpen(false);
  };

  const handleRemoveDriverClick = useCallback(async () => {
    const selectionAction = await showAlert({
      variant: 'destructive',
      title: t('driver.confirmAlertTitle'),
      description: t('driver.additionalDriverRemovalConfirmation'),
      primaryActionText: t('common.remove'),
      secondaryActionText: t('common.cancel'),
    });

    if (selectionAction === SelectedAction.Primary) {
      setLoading(true);

      const additionalDriversWithoutRemovedDriver = currentAdditionalDrivers.filter(
        (additionalDriver) => additionalDriver.profile !== driver?.urn
      );

      try {
        const { errors } = await updateAndRefresh(() =>
          updateAdditionalDrivers(bookingEditorId, additionalDriversWithoutRemovedDriver)
        );

        if (errors) {
          await showAlert({
            title: t('error.error'),
            description: `${t('driver.addAdditionalDriverError')}: ${errors?.[0]?.localizedMessage || ''}`,
          });
        } else {
          handleClose(true);
        }
      } catch (error) {
        logError({
          error: {
            message: addAdditionalDriverError,
            supportInformation: {
              transactionType: TransactionTypes.CreateFullRes,
              location: cookieLocation,
              counter: counterCookie?.counterId,
              serviceError: error,
            },
          },
          severity: ErrorSeverity.Fatal,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    bookingEditorId,
    cookieLocation,
    counterCookie?.counterId,
    currentAdditionalDrivers,
    driver?.urn,
    handleClose,
    showAlert,
    t,
    updateAndRefresh,
  ]);

  return (
    <>
      <Dialog
        title={isAdditionalDriver ? t('driver.editAdditionalDriver') : t('driver.editPrimaryDriver')}
        titleActions={[
          <EhiButton key={'changePrimaryDriver'} variant='text' onClick={handleChangePrimaryDriverClick}>
            {isAdditionalDriver ? t('driver.makePrimaryDriver') : t('driver.changePrimaryDriver')}
          </EhiButton>,
        ]}
        open={open}
        onClose={(): void => handleClose(false)}
        contentPadding={0}
        a11yKey='content'
        showDividers
        maxWidth={'md'}
        fullWidth
        actions={{
          primaryAction: {
            label: t('common.update'),
            onClick: submitForm,
          },
          secondaryAction: {
            label: t('common.cancel'),
            onClick: () => handleClose(false),
          },
          ...(isAdditionalDriver
            ? {
                tertiaryAction: {
                  label: t('driver.removeDriver'),
                  type: 'error',
                  onClick: () => handleRemoveDriverClick(),
                },
              }
            : {}),
        }}>
        <ContentContainer>
          <DriverForm
            isUpdatingPrimaryDriver={!isAdditionalDriver}
            onClose={handleClose}
            formRef={formRef}
            driver={driver}
            data-testId={'editDriverDialog'}
            changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
            setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
          />
        </ContentContainer>
      </Dialog>
      <ProgressOverlay inProgress={loading} />
      <ConfirmationDialog
        open={primaryDriverConfirmationOpen}
        onConfirm={handleConfirmChangePrimaryDriver}
        onCancel={handleCancelChangePrimaryDriver}
        title={t('driver.changePrimaryDriver')}
        description={t('driver.additionalDrivers.changePrimaryPrompt')}
        options={[
          { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
          { label: t('driver.removeFromReservation'), value: REMOVE },
        ]}
        defaultSelection={undefined}
      />
    </>
  );
};
