import { FC, JSX } from 'react';
import { Body2, ehiTheme } from '@ehi/ui';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AdditionalInfoAccountType,
  AdditionalInfoData,
  DropDownOption,
  EditAdditionalInfoProps,
  FieldType,
  UIAdditionalInfoField,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { AdditionalInfoHelperText } from 'components/flexFlow/rateAndBilling/additionalInformation/EditAdditionalInfoDialog.styles';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { Option } from 'components/shared/ui/OptionTypes';
import { SelectField } from 'components/shared/forms/SelectField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { parseUrn } from 'utils/urnUtils';
import { useSaveAdditionalInformation } from 'components/flexFlow/rateAndBilling/additionalInformation/useSaveAdditionalInformation';
import {
  generateSaveRequests,
  getActions,
  getInitialValues,
  validationSchema,
} from 'components/flexFlow/rateAndBilling/additionalInformation/additionalInformationUtils';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { DateTime } from 'luxon';

export const EditAdditionalInfoDialog: FC<EditAdditionalInfoProps> = ({ additionalInfoData, open, onClose }) => {
  const { t } = useTranslations();
  const { saveAdditionalInfo } = useSaveAdditionalInformation();

  const initialValues = getInitialValues(additionalInfoData, t);
  const resolver = useYupValidationResolver(validationSchema(additionalInfoData, t));
  const formMethods = useForm({ resolver: resolver, defaultValues: initialValues });

  const handleSubmit = async (values: Record<string, string | DateTime<boolean> | undefined>): Promise<void> => {
    const requests = generateSaveRequests(values, additionalInfoData, t);

    if (requests.additionalInformation?.length > 0) {
      try {
        await saveAdditionalInfo(requests);
        onClose(SelectedAction.Primary);
      } catch (errors) {
        // Handle this in future validation story
      }
    }
  };

  const onFormSubmit = formMethods.handleSubmit(handleSubmit);

  return (
    <Dialog
      a11yKey={'edit-additional-info'}
      fullWidth={true}
      open={open}
      title={t('rateAndBilling.additionalInformation')}
      actions={getActions(onClose, onFormSubmit, t)}>
      <FormProvider {...formMethods}>
        <EditAdditionalInfoDialogContent info={additionalInfoData} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </FormProvider>
    </Dialog>
  );
};

type ContentProps = {
  info: AdditionalInfoData;
};

const EditAdditionalInfoDialogContent: FC<ContentProps> = ({ info }) => {
  const { t } = useTranslations();
  const formContent = (): JSX.Element => {
    if (!info.additionalInfoFields) {
      return <></>;
    }
    return (
      <Box sx={{ maxWidth: '100%' }}>
        <GridContainer style={{ margin: ehiTheme.spacing(1) }} alignItems='center'>
          <Body2 style={{ marginRight: ehiTheme.spacing(0.5) }}>
            {info.type === AdditionalInfoAccountType.RATE_SOURCE
              ? t('rateAndBilling.forRateSourceAccount')
              : t('rateAndBilling.forBillToAccount')}
          </Body2>
          <Body2 bold>{info.accountName}</Body2>
        </GridContainer>
        <GridContainer>
          {info.additionalInfoFields.map((field) => transformToFormField(info.accountNumber, field))}
        </GridContainer>
      </Box>
    );
  };
  return <>{formContent()}</>;
};

const transformToFormField = (accountNumber: string, field: UIAdditionalInfoField): JSX.Element => {
  const fieldId = parseUrn(field.fieldId);
  const baseFieldProperties = {
    name: `${accountNumber}_${fieldId}`,
    label: field.fieldName,
    required: field.isRequiredAtReservation,
  };
  switch (field.fieldInfo.type) {
    case FieldType.TEXT: {
      return (
        <GridItem sm={6} key={field.fieldId} data-testid={`${accountNumber}-${field.fieldName}`}>
          {field.dataType === FieldType.DATE ? (
            <DatePickerField {...baseFieldProperties} />
          ) : (
            <FormTextField {...baseFieldProperties} />
          )}
          <AdditionalInfoHelperText>{field.helperText}</AdditionalInfoHelperText>
        </GridItem>
      );
    }
    case FieldType.DROP_DOWN: {
      const dropDownFieldInfo = field.fieldInfo;
      return (
        <GridItem sm={6} key={field.fieldId} data-testid={`${accountNumber}-${field.fieldName}`}>
          <SelectField
            {...baseFieldProperties}
            options={dropDownFieldInfo?.dropDownOption?.map((option: DropDownOption) => {
              return { label: option.text, value: option.text } as Option;
            })}
          />
          <AdditionalInfoHelperText>{field.helperText}</AdditionalInfoHelperText>
        </GridItem>
      );
    }
  }
};
