import { loadItem, removeItem, saveItem, WORKFLOW_ID_KEY } from 'utils/localStorageUtils';
import {
  EDITOR_SESSION_STORAGE_KEY,
  EditorSessionData,
  RESERVATION_SESSION_STORAGE_KEY,
  ReservationSessionData,
} from 'components/shared/preprocessor/reservationDataTypes';
import { nowIso } from 'utils/dateUtils';

export const generateWorkflowId = (eid: string, resNumber?: string): void => {
  const workflowId = eid + ':' + Date.now().toString() + ':' + resNumber;
  saveItem(WORKFLOW_ID_KEY, workflowId);
};

export const getLocationHeaderFromUrl = (path: string): string => {
  const headerLocation = path?.split('/');
  return headerLocation?.[headerLocation.length - 1];
};

export const persistReservationSessionData = (resNumber: string, data: ReservationSessionData): void => {
  saveItem(RESERVATION_SESSION_STORAGE_KEY, {
    [resNumber]: { ...data, startTime: nowIso() },
  });
};

export const getPersistedReservationData = (resNumber: string): ReservationSessionData | undefined => {
  return loadItem(RESERVATION_SESSION_STORAGE_KEY)?.[resNumber];
};

export const clearPersistedReservationData = (): void => {
  removeItem(RESERVATION_SESSION_STORAGE_KEY);
};

export const persistEditorSessionData = (data: EditorSessionData): void => {
  saveItem(EDITOR_SESSION_STORAGE_KEY, { ...data, startTime: nowIso() });
};

export const getPersistedEditorSessionData = (): EditorSessionData | undefined => {
  return loadItem(EDITOR_SESSION_STORAGE_KEY);
};

export const clearPersistedEditorSessionData = (): void => {
  removeItem(EDITOR_SESSION_STORAGE_KEY);
};

export const persistEditorSessionDataWithResNumber = (resNumber: string, data: EditorSessionData): void => {
  saveItem(EDITOR_SESSION_STORAGE_KEY, {
    [resNumber]: { ...data, startTime: nowIso() },
  });
};

export const getPersistedEditorSessionDataForResNumber = (resNumber: string): EditorSessionData | undefined => {
  return loadItem(EDITOR_SESSION_STORAGE_KEY)?.[resNumber];
};
