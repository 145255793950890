import { FC, useEffect, useMemo } from 'react';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { Map, useMap } from '@vis.gl/react-google-maps';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { ClusteredBranchLookupMarkers } from './ClusteredBranchLookupMarkers';
import { useMediaQuery } from '@mui/material';

type BranchLookupMapProps = {
  branches: BranchLookupCard[];
  scrollAndSelectBranch: (stationId?: string) => void;
  selectedBranch?: BranchLookupCard;
};
const MAP_OFFSET_HEIGHT = 370;
export const BranchLookupMap: FC<BranchLookupMapProps> = ({ branches, scrollAndSelectBranch, selectedBranch }) => {
  const map = useMap();
  const appConfig = getAppConfigCache();
  const googleMapId = appConfig?.branchLookupGoogleMapId;
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const branchPositions = useMemo(() => {
    return branches.map((branch, index) => {
      return {
        key: index + 1,
        position: branch.geoLocation,
        stationId: branch.stationId,
      };
    });
  }, [branches]);

  useEffect(() => {
    if (map && branches[0].geoLocation) {
      // We need this to update the center once the map is Initialized based on search
      map?.setCenter(selectedBranch?.geoLocation ? selectedBranch.geoLocation : branches[0].geoLocation);
    }
  }, [map, branches, selectedBranch]);

  return (
    <Map
      style={{
        height: isMobileOrTablet
          ? window.innerHeight - window.innerHeight * 0.06 - MAP_OFFSET_HEIGHT - 60
          : window.innerHeight - window.innerHeight * 0.06 - MAP_OFFSET_HEIGHT,
        width: isMobileOrTablet ? '96vw' : '51vw',
        position: 'relative',
      }}
      defaultZoom={13}
      mapId={googleMapId}
      streetViewControl={false}
      fullscreenControl={false}
      mapTypeControl={false}
      keyboardShortcuts={false}
      defaultCenter={branches[0].geoLocation}>
      <ClusteredBranchLookupMarkers
        branches={branchPositions}
        handleMarkerClick={(stationId): void => scrollAndSelectBranch(stationId)}
      />
    </Map>
  );
};
