import { isDevBuild } from 'utils/buildInfoUtil';

export const logInfoWithStyledMessage = (style: string, message: unknown, ...logParams: unknown[]): void => {
  if (isDevBuild()) {
    if (logParams.length) {
      console.info('%c [INFO] %c%s %O', 'color: #2f8a41; font-weight: bold;', style, message, ...logParams);
    } else {
      console.info('%c [INFO] %c%s', 'color: #2f8a41; font-weight: bold;', style, message);
    }
  }
};

/**
 * Wrapper for {@link console.debug}, logs at verbose level, adds styled [DEBUG] prefix
 */
export const logDebug = (...logParams: unknown[]): void => {
  logDebugWithStyledMessage('', '', ...logParams);
};

/**
 * Wrapper for {@link console.debug}, logs at verbose level.
 * Allows for styling beyond [DEBUG]
 * @param {string} style styling for message
 * @param {string} message message to be styled
 * @param {unknown[]}logParams other params, messages, objects, etc
 */
export const logDebugWithStyledMessage = (style: string, message: string, ...logParams: unknown[]): void => {
  if (isDevBuild()) {
    console.debug('%c [DEBUG] %c%s %O', 'color: #0084ff; font-weight: bold;', style, message, ...logParams);
  }
};

// Creates deep clone so we don't loose data
export const getSafeLog = (message: any): any => (message ? JSON.parse(JSON.stringify(message)) : '');
