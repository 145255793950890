import { Address } from 'services/renter/driverProfile/driverProfileTypes';
import { parseUrn } from 'utils/urnUtils';

export const getCityStateZipAddress = (address: Address | undefined): string => {
  const cityStateZip = [];
  const cityString = address?.city && address.countrySubdivision ? `${address.city}, ` : address?.city;
  address?.city && cityStateZip.push(cityString);
  address?.countrySubdivision && cityStateZip.push(parseUrn(address.countrySubdivision));
  address?.postalCode && cityStateZip.push(address.postalCode);
  address?.country && cityStateZip.push(parseUrn(address.country));
  return cityStateZip.join(' ');
};
