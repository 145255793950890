import { LoadingState } from './LoadableViewTypes';

export const mapLoadingState = (isFetching: boolean, isError: boolean): LoadingState => {
  if (isError) {
    return LoadingState.ERROR;
  } else if (isFetching) {
    return LoadingState.LOADING;
  } else {
    return LoadingState.SUCCESS;
  }
};
