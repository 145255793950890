import { FC, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { BranchLookupFields, SearchByType } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { BranchLookupRadioGroup } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupRadioGroup';
import {
  buttonImages,
  StyledActionsWrapper,
  StyledBranchLookUpSearchBox,
  StyledButtonContainer,
  StyledSearchInput,
} from './BranchLookup.styles';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import SearchIcon from '@mui/icons-material/Search';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { OptionItem, SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { useLocationInfoQueries } from 'services/location/locationQueries';
import { brandOptions, distanceOptions, locationTypeOptions } from 'utils/branchLookupUtils';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { BranchLookupIconButton } from './BranchLookupIconButton';
import {
  DESKTOP_WIDTH_PERCENT,
  TABLET_WIDTH_PERCENT,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupModal';

type BranchLookupSearchProps = {
  onSearch: () => void;
  handleReset: () => void;
  groupOptions: OptionItem[];
  hasResults: boolean;
  onMapViewToggle: (isMap: boolean) => void;
};

export const BranchLookupSearch: FC<BranchLookupSearchProps> = ({
  groupOptions,
  handleReset,
  onSearch,
  hasResults,
  onMapViewToggle,
}) => {
  const { t } = useTranslations();
  const { resetField, watch, setValue } = useFormContext();
  const { branchCountryCode, corporateCountries, otherCountries } = useLocationInfoQueries();
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const searchInput = watch(BranchLookupFields.SearchInputValue);
  const searchType = watch(BranchLookupFields.SearchBy);

  const listButtonOnClick = useCallback(() => {
    setValue(BranchLookupFields.IsListButtonSelected, true);
    setValue(BranchLookupFields.IsMapButtonSelected, false);
    setValue(BranchLookupFields.SelectedBranch, undefined);
    onMapViewToggle(false);
  }, [onMapViewToggle, setValue]);

  const mapButtonOnClick = useCallback(() => {
    setValue(BranchLookupFields.IsListButtonSelected, false);
    setValue(BranchLookupFields.IsMapButtonSelected, true);
    setValue(BranchLookupFields.SelectedBranch, undefined);
    onMapViewToggle(true);
  }, [onMapViewToggle, setValue]);
  const [isListButtonSelected, isMapButtonSelected] = useWatch({
    name: [
      BranchLookupFields.IsListButtonSelected,
      BranchLookupFields.IsMapButtonSelected,
      BranchLookupFields.SearchBy,
    ],
  });
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={ehiTheme.spacing(3)}>
      <StyledBranchLookUpSearchBox width={isMobileOrTablet ? TABLET_WIDTH_PERCENT : DESKTOP_WIDTH_PERCENT}>
        <BranchLookupRadioGroup />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <StyledSearchInput
              name={BranchLookupFields.SearchInputValue}
              data-testid={BranchLookupFields.SearchInputValue}
              placeholder={
                searchType === SearchByType.PostalCode ? t('location.postalCode') : t('whenWhere.locationSearchLabel')
              }
              InputProps={{
                startAdornment: <InputIconButton position={'start'} icon={<SearchIcon />} />,
                endAdornment: !!searchInput?.length && (
                  <InputIconButton
                    data-testid={BranchLookupFields.SearchInputValue.concat('ClearIcon')}
                    icon={<FieldClearIcon />}
                    label={t('common.clear')}
                    onClick={(): void => resetField(BranchLookupFields.SearchInputValue)}
                  />
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectMenu
              formFieldName={BranchLookupFields.Country}
              label={t('location.country')}
              required
              primaryList={{
                list: corporateCountries,
                subHeader: t('location.corporateCountries'),
              }}
              secondaryList={{
                list: otherCountries,
                subHeader: t('location.otherCountries'),
              }}
              selectionVariant={SelectionVariant.CHECKMARK}
              searchFieldPlaceholder={t('location.searchForCountry')}
              footerText={t('common.reset')}
              defaultSelection={branchCountryCode}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <SelectMenu
              formFieldName={BranchLookupFields.Groups}
              label={t('whenWhere.groupNumber')}
              required
              primaryList={{
                list: groupOptions,
                subHeader: t('whenWhere.groupNumbers'),
              }}
              selectionVariant={SelectionVariant.CHECKBOX}
              allToggleLabel={t('whenWhere.allGroupNumbers')}
              searchFieldPlaceholder={t('whenWhere.searchForGroupNumber')}
              footerText={t('common.reset')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectMenu
              formFieldName={BranchLookupFields.Brands}
              label={t('whenWhere.allBrands')}
              primaryList={{
                list: brandOptions(t),
              }}
              selectionVariant={SelectionVariant.CHECKBOX}
              allToggleLabel={t('whenWhere.allBrands')}
              inputAllText={t('whenWhere.allBrands')}
              footerText={t('common.clear')}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectMenu
              formFieldName={BranchLookupFields.LocationTypes}
              label={t('location.allLocationTypes')}
              primaryList={{
                list: locationTypeOptions(t),
              }}
              selectionVariant={SelectionVariant.CHECKBOX}
              allToggleLabel={t('location.allLocationTypes')}
              inputAllText={t('location.allLocationTypes')}
              footerText={t('common.clear')}
            />
          </Grid>
          {searchType === SearchByType.PostalCode && (
            <Grid item xs={12} sm={3}>
              <SelectMenu
                formFieldName={BranchLookupFields.Distance}
                label={t('location.distance')}
                primaryList={{
                  list: distanceOptions(t('location.miles')),
                }}
                selectionVariant={SelectionVariant.RADIO}
                footerText={t('common.clear')}
              />
            </Grid>
          )}
        </Grid>
        <StyledActionsWrapper>
          <div>
            <BranchLookupIconButton
              name={'branchLookupListButton'}
              disabled={!hasResults}
              icon={buttonImages.listIcon}
              selected={isListButtonSelected}
              onClick={listButtonOnClick}
            />
            <BranchLookupIconButton
              name={'branchLookupMapButton'}
              disabled={!hasResults}
              icon={buttonImages.mapIcon}
              selected={isMapButtonSelected}
              onClick={mapButtonOnClick}
            />
          </div>
          <StyledButtonContainer>
            <EhiButton data-testid={'branchLookupReset'} onClick={handleReset}>
              {t('common.reset')}
            </EhiButton>
            <EhiButton
              primary
              name={'branchLookupSearch'}
              data-testid={'branchLookupSearch'}
              onClick={onSearch}
              disabled={!searchInput}
              style={{ width: 'auto' }}>
              {t('common.search')}
            </EhiButton>
          </StyledButtonContainer>
        </StyledActionsWrapper>
      </StyledBranchLookUpSearchBox>
    </Box>
  );
};
