import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FlexiFlowMenu } from 'components/shared/flexiFlow/FlexiFlowMenu';
import { InlineReservationSummary } from 'components/resSummary/InlineReservationSummary';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { FlexiFlowRouterProps } from './FlexFlowTypes';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ehiTheme } from '@ehi/ui';
import { ReservationAlerts } from 'components/shared/reservationAlerts/ReservationAlerts';

export const FlexMenuWrapper: FC<FlexiFlowRouterProps> = ({ pages }) => {
  const { openDrawer } = useContext(ResSummaryDrawerContext);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  return (
    <Box display={'flex'} width={'100%'}>
      {openDrawer && <InlineReservationSummary />}
      <Box display={'flex'} width={'100%'} justifyContent={'center'}>
        <Box padding={ehiTheme.spacing(1, 2.4, 2.5)} width={openDrawer || isMobileOrTablet ? '95%' : '80%'}>
          <FlexiFlowMenu pages={pages} />
          <ReservationAlerts />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
