import {
  AirportBranchSearchCriteria,
  BranchTypeSearch,
  BusinessType,
  GroupSummary,
} from 'services/location/locationTypes';
import {
  BranchLookupFields,
  BranchLookupValues,
  RentalBrand,
  SearchByType,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { Brand, EMPTY_VALUE } from 'utils/constants';
import { TFunction } from 'i18next';
import { EHI_DOMAINS, generateReferenceUrn } from './urnUtils';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';

export enum DisplayedLocationTypes {
  Airport = 'airport',
  Exotics = 'exotics',
  HomeCity = ' homeCity',
  Port = 'port',
  TrainStation = 'trainStation',
  Truck = 'truck',
}

export const DAILY_RENTAL = 'DAILY_RENTAL';

export const getGroupOptions = (groups?: GroupSummary[]): OptionItem[] => {
  return groups && Array.isArray(groups)
    ? groups.map((group: GroupSummary) => ({
        label: group.description ?? EMPTY_VALUE,
        id: group.urn,
      }))
    : [];
};

export const getBrandOptions = (brands?: RentalBrand[]): string[] => {
  return brands ? brands.map(({ code }: { code: string }) => code) : [Brand.ENTERPRISE, Brand.ALAMO, Brand.NATIONAL];
};

export enum DistanceValues {
  TEN = '10',
  TWENTY_FIVE = '25',
  FIFTY = '50',
  HUNDRED = '100',
}

export const distanceOptions = (distanceText: string): OptionItem[] => [
  { label: '10 '.concat(distanceText), id: DistanceValues.TEN },
  { label: '25 '.concat(distanceText), id: DistanceValues.TWENTY_FIVE },
  { label: '50 '.concat(distanceText), id: DistanceValues.FIFTY },
  { label: '100 '.concat(distanceText), id: DistanceValues.HUNDRED },
];

export const brandOptions = (t: TFunction<'translation'>): OptionItem[] => [
  { label: t('location.alamoBrand'), id: Brand.ALAMO },
  { label: t('location.enterpriseBrand'), id: Brand.ENTERPRISE },
  { label: t('location.nationalBrand'), id: Brand.NATIONAL },
];

export const locationTypeOptions = (t: TFunction<'translation'>): OptionItem[] => [
  { label: t('location.airport'), id: DisplayedLocationTypes.Airport },
  { label: t('location.exotics'), id: DisplayedLocationTypes.Exotics },
  { label: t('location.homeCity'), id: DisplayedLocationTypes.HomeCity },
  { label: t('location.port'), id: DisplayedLocationTypes.Port },
  { label: t('location.trainStation'), id: DisplayedLocationTypes.TrainStation },
  { label: t('location.truck'), id: DisplayedLocationTypes.Truck },
];

export const getBranchLookupInitialValues = (t: TFunction<'translation'>): BranchLookupValues => {
  return {
    [BranchLookupFields.SearchBy]: SearchByType.Keyword,
    [BranchLookupFields.Country]: EMPTY_VALUE,
    [BranchLookupFields.Groups]: [],
    [BranchLookupFields.LocationTypes]: locationTypeOptions(t).map((locationType) => locationType.id),
    [BranchLookupFields.Brands]: [],
    [BranchLookupFields.Distance]: DistanceValues.TWENTY_FIVE,
    [BranchLookupFields.IsListButtonSelected]: true,
    [BranchLookupFields.IsMapButtonSelected]: false,
    [BranchLookupFields.SearchInputValue]: EMPTY_VALUE,
    [BranchLookupFields.SelectedBranch]: undefined,
  };
};

export const getBranchTypes = (locationTypes: string[]): BranchTypeSearch[] | undefined => {
  if (
    (locationTypes.includes(DisplayedLocationTypes.HomeCity) ||
      locationTypes.includes(DisplayedLocationTypes.Airport)) &&
    locationTypes.includes(DisplayedLocationTypes.Truck)
  ) {
    return [BranchTypeSearch.DAILY_RENTAL, BranchTypeSearch.TRUCK_RENTAL];
  } else if (locationTypes.includes(DisplayedLocationTypes.Truck)) {
    return [BranchTypeSearch.TRUCK_RENTAL];
  } else if (locationTypes.includes(DisplayedLocationTypes.HomeCity)) {
    return [BranchTypeSearch.DAILY_RENTAL];
  } else {
    return;
  }
};

export const getAirportType = (locationTypes: string[]): AirportBranchSearchCriteria | undefined => {
  if (locationTypes?.length === 1 && locationTypes.includes(DisplayedLocationTypes.Airport)) {
    return AirportBranchSearchCriteria.ONLY_AIRPORTS;
  } else if (
    locationTypes.includes(DisplayedLocationTypes.HomeCity) &&
    !locationTypes.includes(DisplayedLocationTypes.Airport)
  ) {
    return AirportBranchSearchCriteria.EXCLUDE_AIRPORTS;
  } else if (
    !locationTypes.includes(DisplayedLocationTypes.HomeCity) &&
    locationTypes.includes(DisplayedLocationTypes.Airport)
  ) {
    return AirportBranchSearchCriteria.ONLY_AIRPORTS;
  } else {
    return;
  }
};

const generateBusinessIndicatorUrn = (indicator: string, defaultEhiDatabase: string): string => {
  return generateReferenceUrn(
    EHI_DOMAINS.location.name,
    EHI_DOMAINS.location.businessIndicator,
    indicator,
    defaultEhiDatabase
  );
};

export const getBusinessIndicators = (locationTypes: string[], defaultEhiDatabase: string): string[] | undefined => {
  const businessIndicators = locationTypes
    .map((type) => {
      switch (type) {
        case DisplayedLocationTypes.Exotics:
          return generateBusinessIndicatorUrn(BusinessType.EXOTIC_CAR_LOCATION, defaultEhiDatabase);
        case DisplayedLocationTypes.Port:
          return generateBusinessIndicatorUrn(BusinessType.PORT_OF_CALL, defaultEhiDatabase);
        case DisplayedLocationTypes.TrainStation:
          return generateBusinessIndicatorUrn(BusinessType.TRAIN_STATION, defaultEhiDatabase);
        default:
          return;
      }
    })
    .filter((item) => !!item) as string[];
  return businessIndicators?.length > 0 ? businessIndicators : undefined;
};
