import { MoreMenu } from '@ehi/global-navigation';
import { ActionOptions, EhiAlertDialog } from '@ehi/ui';
import { RouterPaths } from 'app/router/RouterPaths';
import { useTranslations } from 'components/shared/i18n';
import { useSupport } from 'hooks/useSupport';
import { useNavigate } from 'react-router-dom';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { FC } from 'react';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};
export const QuickResCancelModal: FC<ModalProps> = ({ open, onClose }) => {
  const { t } = useTranslations();
  const { goToSupport } = useSupport();
  const navigate = useNavigate();
  const { clearEditorSession } = useReservationSessionHelper();
  const moreMenuItems = [
    [
      {
        title: t('common.support'),
        onClick: (): void => {
          onClose();
          goToSupport();
        },
      },
    ],
  ];
  const actions: ActionOptions = {
    primaryAction: {
      label: t('common.yes'),
      onClick: () => {
        clearEditorSession();
        navigate(RouterPaths.Search);
      },
    },
    secondaryAction: {
      label: t('common.no'),
      onClick: () => {
        onClose();
      },
    },
  };
  return (
    <EhiAlertDialog
      style={{ whiteSpace: 'pre-line' }}
      open={open}
      title={t('quickReservation.cancelTitle')}
      description={t('quickReservation.cancelDescription')}
      titleActions={[<MoreMenu key={'more-menu'} moreMenuSections={moreMenuItems} />]}
      actions={actions}></EhiAlertDialog>
  );
};
