import { FC, ReactElement, useMemo, useState } from 'react';
import { useRetrieveBranchEffectivePolicies } from 'services/location/locationQueries';
import { GridVerticalContainer } from 'components/shared/ui/styles/Grid.styles';
import { Body1, ehiTheme, H6 } from '@ehi/ui';
import { Grid } from '@mui/material';
import { ExpandLessButton, ExpandMoreButton } from 'components/shared/ui/styles/Global.styles';
import { StyledLink } from 'components/search/NoResults.styles';
import { selectPickup } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { parseUrn } from 'utils/urnUtils';
import { useTranslations } from 'components/shared/i18n';
import { BranchEffectivePolicy } from 'services/location/locationTypes';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useAlert } from 'components/shared/alert/AlertContext';
import { reorderPolices } from 'utils/reviewPageUtils';

export const PoliciesSection: FC = () => {
  const { t } = useTranslations();
  const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState<boolean>(false);
  const { showAlert } = useAlert();
  const pickupData = useAppSelector(selectPickup);
  const { data: policies, isFetching } = useRetrieveBranchEffectivePolicies(parseUrn(pickupData?.branch), t);
  const reOrderedPolicies = useMemo(() => reorderPolices(policies), [policies]);
  const policiesSplitLength = reOrderedPolicies && Math.ceil(reOrderedPolicies.length / 2);

  const policyLink = (index: number, policy: BranchEffectivePolicy, columnNumber: number): ReactElement => {
    return (
      <div key={index} style={{ padding: ehiTheme.spacing(0.5, 0) }}>
        <StyledLink
          data-testid={`column-${columnNumber}-policy-${index}`}
          onClick={(): void => {
            showAlert({
              title: t('review.policyInformation'),
              description: (
                <>
                  <H6 data-testid={'policyName'}>{policy?.localizedName}</H6>
                  <Body1 data-testid={'policyContent'}> {policy?.version?.content ?? ''}</Body1>
                </>
              ),
            });
          }}>
          {policy?.localizedName}
        </StyledLink>
      </div>
    );
  };

  return (
    <GridVerticalContainer data-testid={'reviewPoliciesSection'}>
      <Grid item>
        <Grid container paddingBottom={ehiTheme.spacing(2)} paddingTop={ehiTheme.spacing(2)}>
          <Grid item xs={11} sm={11}>
            <Grid item>
              <H6>{t('review.policies')}</H6>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Grid textAlign={'center'} alignSelf={'center'}>
              {additionalInfoExpanded ? (
                <ExpandLessButton
                  data-testid={'policiesExpandLessButton'}
                  onClick={(): void => {
                    setAdditionalInfoExpanded(false);
                  }}
                />
              ) : (
                <ExpandMoreButton
                  data-testid={'policiesExpandMoreButton'}
                  onClick={(): void => {
                    setAdditionalInfoExpanded(true);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {additionalInfoExpanded && (
        <Grid item height={'100%'} width={'100%'} data-testid={'policies'}>
          <Grid container paddingBottom={ehiTheme.spacing(2)}>
            <Grid item xs={6} display={'flex'} flexDirection={'column'}>
              {reOrderedPolicies?.slice(0, policiesSplitLength)?.map((policy, index) => policyLink(index, policy, 1))}
            </Grid>
            <Grid item xs={6} display={'flex'} flexDirection={'column'}>
              {reOrderedPolicies?.slice(policiesSplitLength)?.map((policy, index) => policyLink(index, policy, 2))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <ProgressOverlay inProgress={isFetching} />
    </GridVerticalContainer>
  );
};
