import { FC } from 'react';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { useTranslations } from 'components/shared/i18n';
import { Outlet } from 'react-router-dom';
import { ResActionsProvider } from 'context/resActions/ResActionsContext';
import { StyledViewIconButton } from 'app/router/Router.styles';
import { useReservationAlerts } from 'components/shared/reservationAlerts/useReservationAlerts';

export const ViewRouter: FC = () => {
  const { t } = useTranslations();
  const { modifyAllowed } = useReservationAlerts();

  return modifyAllowed ? (
    <ResActionsProvider label={t('common.edit')} icon={<StyledViewIconButton data-testid='viewRouterIcon' />}>
      <DynamicHelmet title={t('common.viewReservation')} />
      <Outlet />
    </ResActionsProvider>
  ) : (
    <>
      <DynamicHelmet title={t('common.viewReservation')} />
      <Outlet />
    </>
  );
};
