import { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ehiTheme } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useTranslations } from 'components/shared/i18n';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  ActionableAlertButton,
  ActionableAlertMessageContainer,
  AlertDescriptionContainer,
  AlertDescriptionText,
} from 'components/shared/alert/AlertDialog.styles';
import { AlertWithActionProps } from 'components/shared/alert/AlertDialogTypes';

export const AlertWithAction: FC<AlertWithActionProps> = ({ description, navigate, alertVariant }) => {
  const { t } = useTranslations();
  const { hideAlert } = useAlert();
  const alertIcon =
    alertVariant === 'warning' ? (
      <InfoOutlinedIcon color={'info'} style={{ paddingRight: ehiTheme.spacing(1) }} />
    ) : (
      <ErrorOutlineIcon color={'error'} style={{ paddingRight: ehiTheme.spacing(1) }} />
    );

  return (
    <ActionableAlertMessageContainer data-testid='actionableAlertMessage'>
      <AlertDescriptionContainer>
        {alertIcon}
        <AlertDescriptionText>{description.message}</AlertDescriptionText>
        {description.routePath && (
          <ActionableAlertButton
            variant='text'
            data-testid='viewButton'
            onClick={(): void => {
              if (description.routePath) {
                navigate(description.routePath);
                hideAlert();
              }
            }}
            size={'small'}>
            {t('common.view')}
          </ActionableAlertButton>
        )}
      </AlertDescriptionContainer>
      <EhiDivider />
    </ActionableAlertMessageContainer>
  );
};
