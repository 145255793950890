import { FieldValues, useFormContext } from 'react-hook-form';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useSaveActionContext } from 'context/saveAction/SaveActionContext';
import { useEffect } from 'react';
import { useResActionsContext } from 'context/resActions/ResActionsContext';

/**
 * @param submitFn function to called on valid form submit
 * @param alwaysBlock block navigation until validation is successful, even if there were no changes to the form
 *
 * @see also SavingFormProvider
 */
export const useFormSaveOnAction = <TValues extends FieldValues>(
  submitFn: (values: TValues) => Promise<ServiceResultType>,
  alwaysBlock?: boolean
): void => {
  const { registerSaveAction } = useSaveActionContext();
  const { formState, handleSubmit, reset, getValues } = useFormContext<TValues>();
  const { setUpdateFloatingButtonAction } = useResActionsContext();

  const onSave = (): Promise<ServiceResultType> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<ServiceResultType>(async (resolve, reject) => {
      let result: ServiceResultType | undefined = undefined;
      await handleSubmit(async (values) => {
        result = await submitFn(values);
      })();

      if (result) {
        resolve(result);
      } else {
        reject(formState.errors);
      }
    });

  const resetForm = (): void => {
    reset(getValues());
  };

  useEffect(() => {
    setUpdateFloatingButtonAction(formState.isDirty);
  }, [formState.isDirty, setUpdateFloatingButtonAction]);

  registerSaveAction({
    onSave: onSave,
    reset: resetForm,
    when: alwaysBlock || formState.isDirty,
  });
};
