import { FC, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import { logError } from 'components/shared/logger/splunkLogger';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { useQueryParamsByKey } from 'utils/routing/routeUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { RouterPaths } from 'app/router/RouterPaths';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';
import { loadCounterCookie, loadEhiLocationCookie } from '@ehi/location';
import { ErrorSeverity } from '@ehi/analytics';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import NetworkError from 'components/shared/errors/NetworkError';
import { FlexFlowLoadingSkeleton } from 'components/shared/ui/skeletons/FlexFlowLoadingSkeleton';

export const ReservationPreProcessor: FC = () => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const cookieLocation = loadEhiLocationCookie();
  const counterCookie = loadCounterCookie();
  const { startRetrieveReservationSession, startOpenEditorSession, startModifyEditorSession } =
    useStartReservationSession();
  const { clearReservationSession } = useReservationSessionHelper();

  const queryParams = useQueryParamsByKey([InternalTransactionParams.Res, InternalTransactionParams.TransactionType]);
  const { [InternalTransactionParams.Res]: resNumber, [InternalTransactionParams.TransactionType]: transactionType } =
    queryParams;

  const startReservationFlow = async (): Promise<void> => {
    const { errors, data: reservationData } = await startRetrieveReservationSession(resNumber);
    if (!errors?.length && reservationData) {
      setLoadingState(LoadingState.SUCCESS);
      navigate(
        {
          pathname: `/res/${resNumber}/view/${RouterPaths.WhenAndWhere}`,
        },
        {
          replace: true,
        }
      );
    } else {
      logError({
        error: {
          message: 'Error Starting Reservation Flow',
          supportInformation: {
            resNumber,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  const viewReservation = async (): Promise<void> => {
    const { errors } = await startRetrieveReservationSession(resNumber);
    if (!errors?.length) {
      setLoadingState(LoadingState.SUCCESS);
      navigate(
        {
          pathname: `/res/${resNumber}/view/${RouterPaths.WhenAndWhere}`,
        },
        {
          replace: true,
        }
      );
    } else {
      logError({
        error: {
          message: 'Error retrieving reservation data',
          supportInformation: {
            resNumber,
            transactionType: TransactionTypes.View,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  const navigateToFullRes = (): void => {
    setLoadingState(LoadingState.SUCCESS);
    navigate(
      {
        pathname: `${RouterPaths.Create}/${RouterPaths.WhenAndWhere}`,
      },
      {
        replace: true,
      }
    );
  };

  const createRes = async (transactionType: TransactionTypes): Promise<void> => {
    const { errors } = await startOpenEditorSession(transactionType);
    if (!errors?.length) {
      setLoadingState(LoadingState.SUCCESS);
      navigateToFullRes();
    } else {
      logError({
        error: {
          message: 'Error Creating Reservation Editor',
          supportInformation: {
            transactionType: transactionType,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  const modifyReservation = async (): Promise<void> => {
    const { errors } = await startModifyEditorSession(resNumber);
    if (!errors?.length) {
      setLoadingState(LoadingState.SUCCESS);
      navigate(
        {
          pathname: `/res/${resNumber}/modify/${RouterPaths.WhenAndWhere}`,
        },
        {
          replace: true,
        }
      );
    } else {
      logError({
        error: {
          message: 'Error Creating Reservation Editor For Modify',
          supportInformation: {
            transactionType: TransactionTypes.Modify,
            location: cookieLocation,
            counter: counterCookie?.counterId,
            serviceError: errors,
          },
        },
        severity: ErrorSeverity.Fatal,
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  const processReservations = async (): Promise<void> => {
    clearReservationSession();
    switch (transactionType) {
      case TransactionTypes.Search:
        await startReservationFlow();
        break;
      case TransactionTypes.View:
        await viewReservation();
        break;
      case TransactionTypes.CreateFullRes:
        await createRes(transactionType);
        break;
      case TransactionTypes.Modify:
        await modifyReservation();
        break;
      default:
        logError({ message: 'Invalid Transaction Type' });
        navigate(RouterPaths.Dev);
    }
  };

  useEffectOnlyOnce(async () => {
    await processReservations();
  });

  return (
    <LoadableView loadingComponent={<FlexFlowLoadingSkeleton />} errorComponent={<NetworkError />} state={loadingState}>
      <Outlet />
    </LoadableView>
  );
};
