import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { ACCEPT, CONTENT_TYPE } from 'services/headerConstants';
import {
  AccountNumberCriteria,
  AdditionalInformationBase,
  BillingAccount,
  BusinessAccountNumber,
  BusinessAccountSearchResult,
  ContactInformation,
  PaginationResponse,
  ReservationAccount,
  SearchAccountRequest,
  ValidateAdditionalInformationRequest,
  ValidationCriteria,
} from 'services/businessAccount/businessAccountTypes';
import { getWarningsFromResponseHeaders } from 'utils/warningParserUtils';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';

const ACCEPT_BUSINESS_ACCOUNT_RESERVATION_FLOWS = 'application/prs.com-ehi.reservation-flow+json';
const ACCEPT_BUSINESS_ACCOUNT_CONTACT_TYPE = 'application/prs.com-ehi.organization-contactInformation+json';
const CONTENT_TYPE_BILLING_ACCOUNT_RETRIEVE = 'application/prs.com-ehi.accountNumbers+json';

const BUSINESS_ACCOUNT_PATH = '/businessAccount';
const ACCOUNT_PATH = '/account';
const BILLING_ACCOUNT_PATH = '/retrieve-billing-account';
const VALIDATE_BILLING_ACCOUNT_PATH = '/validate-billing-account';
const ACCOUNT_CONTACT_PATH = '/contactInformation/MAIN';
const ADDITIONAL_INFORMATION = '/additionalInformation';
const VALIDATE_ADDITIONAL_INFORMATION = '/validate-additional-information';

export type ValidateBillingAccountReturnType = {
  isValid: boolean;
  ehiMessages?: ResponseMessage[];
};

export const retrieveBusinessAccount = async (accountNumber: string): Promise<ReservationAccount> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(
    `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber?.trim().toUpperCase()}`,
    {
      genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}`,
      headers: {
        [ACCEPT]: [ACCEPT_BUSINESS_ACCOUNT_RESERVATION_FLOWS],
      },
    }
  );
  return response?.data;
};

export const retrieveBillingAccount = async (data: AccountNumberCriteria): Promise<BillingAccount> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(`${BUSINESS_ACCOUNT_PATH}${BILLING_ACCOUNT_PATH}`, data, {
    genericUrl: `${BUSINESS_ACCOUNT_PATH}${BILLING_ACCOUNT_PATH}`,
    headers: {
      [CONTENT_TYPE]: [CONTENT_TYPE_BILLING_ACCOUNT_RETRIEVE],
    },
  });
  return response?.data;
};

export const validateBillingAccount = async (
  request: ValidationCriteria
): Promise<ValidateBillingAccountReturnType> => {
  const axiosInstance = getDefaultAxiosInstance();
  const { data, headers } = await axiosInstance.post(
    `${BUSINESS_ACCOUNT_PATH}${VALIDATE_BILLING_ACCOUNT_PATH}`,
    request,
    {
      genericUrl: `${BUSINESS_ACCOUNT_PATH}${VALIDATE_BILLING_ACCOUNT_PATH}`,
    }
  );
  return { isValid: data, ehiMessages: getWarningsFromResponseHeaders(headers) };
};

export const getAccountContactInfo = async (accountNumber: string): Promise<ContactInformation> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(
    `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber?.trim().toUpperCase()}${ACCOUNT_CONTACT_PATH}`,
    {
      genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}${ACCOUNT_CONTACT_PATH}`,
      headers: {
        [ACCEPT]: [ACCEPT_BUSINESS_ACCOUNT_CONTACT_TYPE],
      },
    }
  );
  return response?.data;
};

export const searchBusinessAccounts = async (
  payload: SearchAccountRequest
): Promise<{
  pagination?: PaginationResponse;
  businessAccounts?: BusinessAccountSearchResult[];
}> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance?.post(`${BUSINESS_ACCOUNT_PATH}/search-account`, payload, {
    genericUrl: `${BUSINESS_ACCOUNT_PATH}/search-account`,
  });
  return response?.data;
};

export const retrieveAdditionalInformation = async (accountNumber: string): Promise<AdditionalInformationBase[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(
    `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber?.trim().toUpperCase()}${ADDITIONAL_INFORMATION}`,
    {
      genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}${ADDITIONAL_INFORMATION}`,
    }
  );
  return response?.data;
};

export const validateAdditionalInformation = async (
  accountNumber: BusinessAccountNumber,
  payload: ValidateAdditionalInformationRequest
): Promise<boolean> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance?.post(
    `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/${accountNumber?.trim().toUpperCase()}${VALIDATE_ADDITIONAL_INFORMATION}`,
    payload,
    {
      genericUrl: `${BUSINESS_ACCOUNT_PATH}${ACCOUNT_PATH}/{accountNumber}${VALIDATE_ADDITIONAL_INFORMATION}`,
    }
  );
  return response?.data;
};
