import { ServiceState } from 'services/types/ServiceStateTypes';
import {
  AdditionalInfoAccountType,
  AdditionalInfoData,
  AdditionalInfoHookReturn,
  DropDownField,
  DropDownOption,
  FieldInfo,
  FieldType,
  TextField,
  UIAdditionalInfoField,
} from 'components/flexFlow/rateAndBilling/additionalInformation/AdditionalInfoTypes';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { useAdditionalInformationQuery } from 'services/businessAccount/accountQueries';
import { useMemo } from 'react';
import {
  AdditionalInformationBase,
  AdditionalInformationText,
  RentalStage,
} from 'services/businessAccount/businessAccountTypes';
import { AdditionalInformation, NegotiatedRateSource, RateSource } from 'services/booking/bookingTypes';
import { useAppSelector } from 'redux/hooks';
import { selectBillingAccountAdditionalInformation, selectRateSource } from 'redux/selectors/bookingEditor';
import { transformAdditionalInformationInfoFromApi } from 'components/shared/uiModels/rateSource/rateSourceTransformer';

export const useRetrieveAdditionalInformation = (accountType: AdditionalInfoAccountType): AdditionalInfoHookReturn => {
  const {
    accountNumber,
    billingAccountNumber,
    isBillingAccountInfoLoading,
    isRateSourceInfoLoading,
    rateSourceErrors,
    rateSourceInfo,
    billingAccountInfo,
    billingAccountInfoErrors,
    billingNumber,
  } = useAccountDetails();

  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const negotiatedRateSource = rateSource as NegotiatedRateSource;
  const editorBillingAccountAdditionalInformation = useAppSelector(selectBillingAccountAdditionalInformation);

  const isRateSourceAccount = accountType === AdditionalInfoAccountType.RATE_SOURCE;

  const additionalInfoAccountNumber = isRateSourceAccount ? accountNumber : billingAccountNumber;

  const { data, isFetching: isAdditionalInformationLoading } =
    useAdditionalInformationQuery(additionalInfoAccountNumber);

  const additionalInfo = useMemo(() => {
    return transformAdditionalInformationInfoFromApi(data ?? [], accountType);
  }, [data, accountType]);

  const editorAdditionalInfo: AdditionalInformation[] = useMemo(() => {
    const addInfo = [];

    if (negotiatedRateSource && negotiatedRateSource.additionalInformation) {
      addInfo.push(...negotiatedRateSource.additionalInformation);
    }

    if (editorBillingAccountAdditionalInformation) {
      addInfo.push(...editorBillingAccountAdditionalInformation);
    }

    return addInfo;
  }, [negotiatedRateSource, editorBillingAccountAdditionalInformation]);

  const additionalInfoData: AdditionalInfoData = useMemo(() => {
    return {
      accountNumber: additionalInfoAccountNumber,
      accountName: isRateSourceAccount ? rateSourceInfo?.name : billingAccountInfo?.name,
      accountUrn: isRateSourceAccount ? rateSourceInfo?.urn : billingAccountInfo?.urn,
      billingNumber: billingNumber,
      additionalInfoFields: additionalInfo
        ?.map((field: AdditionalInformationBase) => adaptAdditionalInfoField(field, editorAdditionalInfo))
        ?.sort((a, b) => (a.ordinal as number) - (b.ordinal as number)),
      type: accountType,
    } as AdditionalInfoData;
  }, [
    additionalInfo,
    additionalInfoAccountNumber,
    isRateSourceAccount,
    accountType,
    rateSourceInfo,
    billingAccountInfo,
    billingNumber,
    editorAdditionalInfo,
  ]);

  const combinedErrors = [];
  if (rateSourceErrors?.errors && rateSourceErrors?.errors.length > 0) {
    combinedErrors.push(...rateSourceErrors.errors);
  }
  if (billingAccountInfoErrors?.errors && billingAccountInfoErrors?.errors.length > 0) {
    combinedErrors.push(...billingAccountInfoErrors.errors);
  }

  const combinedRetrieveState: ServiceState = {
    loading: isBillingAccountInfoLoading || isRateSourceInfoLoading || isAdditionalInformationLoading,
    errors: combinedErrors,
  };
  return {
    additionalInfo: additionalInfoData,
    ...combinedRetrieveState,
  };
};

export const adaptAdditionalInfoField = (
  additionalInfoField: AdditionalInformationBase,
  editorAdditionalInfo: AdditionalInformation[]
): UIAdditionalInfoField => {
  const reservationEditorFieldData = editorAdditionalInfo.find((field) =>
    field?.fieldId?.includes(additionalInfoField.urn.toString())
  );

  return {
    fieldName: additionalInfoField?.translation?.name ?? '',
    fieldValue: reservationEditorFieldData?.value,
    dataType: (additionalInfoField as AdditionalInformationText)?.dataType,
    ordinal: additionalInfoField?.ordinal,
    fieldId: additionalInfoField?.urn,
    helperText: additionalInfoField?.translation?.helperText ?? '',
    isRequiredAtReservation: additionalInfoField?.requiredRentalStages?.includes(RentalStage.RESERVATION) ?? false,
    fieldInfo: transformFieldType(additionalInfoField),
  };
};

const transformFieldType = (field: AdditionalInformationBase): FieldInfo => {
  switch (field.type) {
    case FieldType.DROP_DOWN: {
      return {
        type: FieldType.DROP_DOWN,
        // Fix: Types are wrongly defined in the AdditionalInformationBase for dropDownValues
        // should be dropDownValues instead just values
        dropDownOption: (field as any)?.dropDownValues
          ?.sort((a: { ordinal: number }, b: { ordinal: number }) => a.ordinal - b.ordinal)
          ?.map((option: { value: string }) => {
            return {
              text: option.value,
            } as DropDownOption;
          }),
      } as DropDownField;
    }
    default: {
      return {
        type: FieldType.TEXT,
      } as TextField;
    }
  }
};
