import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import {
  getTransactionalProfile,
  RetrieveTransactionProfile,
} from 'services/renter/transactionalProfile/transactionalProfileService';

const TRANSACTIONAL_PROFILE_QUERY_KEY = 'driverProfile';

export const useGetTransactionalProfileQuery = (
  transactionalProfileId: string,
  queryOptions: Partial<UseQueryOptions<RetrieveTransactionProfile, EhiErrors>> = {}
): UseQueryResult<RetrieveTransactionProfile, EhiErrors> => {
  const queryKey = [TRANSACTIONAL_PROFILE_QUERY_KEY, transactionalProfileId];
  const queryFn = (): Promise<RetrieveTransactionProfile> => getTransactionalProfile(transactionalProfileId);
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    ...queryOptions,
  };

  return useQuery<RetrieveTransactionProfile, EhiErrors>(queryKey, queryFn, options);
};
