import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid, IconButton } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { datesAreSelected, toDateTimeString } from 'utils/dateUtils';
import { useTranslations } from 'components/shared/i18n';
import { ArrowRightAlt } from '@mui/icons-material';
import { useAppSelector } from 'redux/hooks';
import { selectLengthOfRental, selectOneWay, selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { FC, useMemo } from 'react';
import { DOUBLE_DASH } from 'utils/constants';

export const WhenAndWhereSummary: FC = () => {
  const { t } = useTranslations();
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const isOneWay = useAppSelector(selectOneWay);
  const lengthOfRental = useAppSelector(selectLengthOfRental);

  const {
    data: pickupBranch,
    isFetching: isFetchingPickupBranch,
    isError: isPickupBranchError,
  } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const {
    data: returnBranch,
    isFetching: isFetchingReturnBranch,
    isError: isReturnBranchError,
  } = useBranchInfoByUrnQuery(returnData?.branch ?? '');
  const isFetching = isFetchingPickupBranch || isFetchingReturnBranch;
  const isError = isPickupBranchError || isReturnBranchError;

  const formattedPickupDate = useMemo(
    () => toDateTimeString(pickupData?.dateTime, t('format.shortWeekdayDate'), pickupBranch?.timezone),
    [pickupBranch?.timezone, pickupData?.dateTime, t]
  );
  const formattedReturnDate = useMemo(
    () => toDateTimeString(returnData?.dateTime, t('format.shortWeekdayDate'), returnBranch?.timezone),
    [returnBranch?.timezone, returnData?.dateTime, t]
  );
  const formattedPickupTime = useMemo(
    () => toDateTimeString(pickupData?.dateTime, t('format.timeWithZone'), pickupBranch?.timezone),
    [pickupBranch?.timezone, pickupData?.dateTime, t]
  );
  const formattedReturnTime = useMemo(
    () => toDateTimeString(returnData?.dateTime, t('format.timeWithZone'), returnBranch?.timezone),
    [returnBranch?.timezone, returnData?.dateTime, t]
  );
  const haveDatesBeenSelected = datesAreSelected(pickupData?.dateTime, returnData?.dateTime);

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.whenWhere')}
      loading={mapLoadingState(isFetching, isError)}
      path={RouterPaths.WhenAndWhere}
      data-testid='whenAndWhereSummaryCard'>
      <CardContent data-testid='content'>
        {pickupData === undefined && returnData === undefined ? (
          <Subtitle1 color={ehiTheme.palette.text.secondary} data-testid='noDatesMessage'>
            {t('whenWhere.noDatesSelected')}
          </Subtitle1>
        ) : (
          <Grid container direction={'column'} spacing={1}>
            <Grid item xs width={'100%'} data-testid='pickupContent'>
              <Caption2>{t('whenWhere.rentalStart')}</Caption2>
              <div data-testid='date'>
                <Subtitle1 bold display='inline' noWrap={true}>
                  {formattedPickupDate}{' '}
                </Subtitle1>
                <Subtitle1 display='inline' noWrap={true}>
                  {formattedPickupTime}
                </Subtitle1>
              </div>
              <Body1 data-testid='locationInfo' whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
                {pickupBranch?.stationId} {pickupBranch?.operationalDescription}
              </Body1>
            </Grid>
            <Grid item xs width={'100%'} data-testid='returnContent'>
              <Caption2>
                {t('whenWhere.rentalReturn')}
                {isOneWay && (
                  <IconButton data-testid='oneWayIcon'>
                    <ArrowRightAlt fontSize='small' color='secondary' />
                  </IconButton>
                )}
              </Caption2>
              <div data-testid='date'>
                <Subtitle1 bold display='inline' noWrap={true}>
                  {formattedReturnDate}{' '}
                </Subtitle1>
                <Subtitle1 display='inline' noWrap={true}>
                  {formattedReturnTime}
                </Subtitle1>
                {haveDatesBeenSelected && (
                  <Body2 data-testid='lengthOfRental' display={'inline'}>
                    {lengthOfRental
                      ? ` (${lengthOfRental}${lengthOfRental === 1 ? t('whenWhere.day') : t('whenWhere.days')})`
                      : DOUBLE_DASH}
                  </Body2>
                )}
              </div>

              <Body1 data-testid='locationInfo' whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
                {returnBranch?.stationId} {returnBranch?.operationalDescription}
              </Body1>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </ReservationSummaryCard>
  );
};
