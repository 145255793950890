import { EditorIssue } from 'services/booking/bookingTypes';
import { deleteVehicleClassSelection, removeAllAddOns, updateBusinessPayers } from 'services/booking/bookingService';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectDriverProfileRenter,
  selectEquipment,
  selectProtections,
} from 'redux/selectors/bookingEditor';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  hasBillToBookingIssues,
  hasDriverBookingIssue,
  hasPeoBookingIssues,
  hasVehicleAvailabilityBookingIssues,
  hasVehicleYoungDriverBookingIssue,
} from 'utils/bookingUtils';
import { removeDriverProfile } from 'services/renter/driverProfile/driverProfileService';
import { parseUrn } from 'utils/urnUtils';

export type BookingIssueReturn = {
  handleRemoveVehicle: (issues: EditorIssue[]) => Promise<void>;
  handleRemoveAllPeo: (issues: EditorIssue[]) => Promise<void>;
  handleRemoveDriverProfile: (issues: EditorIssue[]) => Promise<void>;
  handleRemoveBillTo: (issues: EditorIssue[]) => Promise<void>;
};

export const useBookingIssue = (): BookingIssueReturn => {
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const equipment = useAppSelector(selectEquipment);
  const protections = useAppSelector(selectProtections);
  const driverProfile = useAppSelector(selectDriverProfileRenter);

  const handleRemoveVehicle = async (issues: EditorIssue[]): Promise<void> => {
    if (hasVehicleYoungDriverBookingIssue(issues) || hasVehicleAvailabilityBookingIssues(issues)) {
      const hasSelectedAddons = !!(equipment || protections);
      await updateAndRefresh(async () => {
        await deleteVehicleClassSelection(bookingEditorId);
        hasSelectedAddons && (await removeAllAddOns(bookingEditorId));
      }).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  const handleRemoveAllPeo = async (issues: EditorIssue[]): Promise<void> => {
    if (hasPeoBookingIssues(issues)) {
      await updateAndRefresh(async () => {
        await removeAllAddOns(bookingEditorId);
      }).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  const handleRemoveDriverProfile = async (issues: EditorIssue[]): Promise<void> => {
    if (hasDriverBookingIssue(issues) && driverProfile?.profile) {
      await updateAndRefresh(async () => removeDriverProfile(parseUrn(driverProfile?.profile))).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  const handleRemoveBillTo = async (issues: EditorIssue[]): Promise<void> => {
    if (hasBillToBookingIssues(issues)) {
      await updateAndRefresh(async () => updateBusinessPayers(bookingEditorId, [])).then(async (result) => {
        if (result.errors) {
          await showAlert({ responseMessages: result.errors });
        }
      });
    }
  };

  return {
    handleRemoveAllPeo,
    handleRemoveVehicle,
    handleRemoveDriverProfile,
    handleRemoveBillTo,
  };
};
