import { FC, useEffect } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import {
  DriverFormFields,
  DriverFormValues,
  DriverType,
  PhoneFieldNames,
} from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { FieldValues, useFieldArray, useFormContext, UseFormSetValue, useWatch } from 'react-hook-form';
import { PhoneSubform } from 'components/flexFlow/driver/driverForm/formSection/PhoneSubForm';
import { PHONE_COUNT_MAX } from 'components/flexFlow/driver/driverForm/driverFormUtils';
import { FormSectionHeader, Section } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { piiField } from '@ehi/support';
import { AddCircle } from '@mui/icons-material';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';

const PhoneSection: FC = () => {
  const { t } = useTranslations();
  const { data: phoneTypeDomains } = usePhoneTypesQuery();
  const { control, getValues, setValue } = useFormContext<DriverFormValues>();
  const { fields, append, replace } = useFieldArray({
    control,
    name: DriverFormFields.Phone,
  });

  const priorities = useWatch({
    name: fields?.map((_, index) => `${DriverFormFields.Phone}[${index}].${PhoneFieldNames.Priority}`),
  });
  const initialPrimaryIndex = priorities?.findIndex((priority) => priority === 1) || 0;

  const removePhone = (index: number): void => {
    const newArray = [...getValues(DriverFormFields.Phone)];
    newArray.splice(index, 1);
    // using replace instead of remove because remove throws an exception all the time
    replace(newArray);
  };

  useEffect(() => {
    // If the "primary" is no longer valid (removed), set the first one to primary
    if (initialPrimaryIndex === -1 || initialPrimaryIndex > priorities.length) {
      // casting setValue because the given type doesn't work well with fieldArrays
      (setValue as unknown as UseFormSetValue<FieldValues>)(
        `${DriverFormFields.Phone}[${0}].${PhoneFieldNames.Priority}`,
        1
      );
    }
  }, [priorities?.length, initialPrimaryIndex, setValue]);

  return (
    <>
      {fields?.map((field, index) => {
        return (
          <PhoneSubform
            key={field.id}
            index={index}
            onRemovePhone={removePhone}
            primaryIndex={initialPrimaryIndex}
            replace={replace}
            length={fields?.length || 0}
            phoneTypeDomainValues={phoneTypeDomains || []}
          />
        );
      })}
      {(!fields || fields.length < PHONE_COUNT_MAX) && (
        <GridItem sm={12} sx={{ margin: ehiTheme.spacing(-2, 2, 0, -2) }}>
          <EhiButton
            startIcon={<AddCircle />}
            data-testid={'addPhoneButton'}
            onClick={(): void =>
              append({
                number: '',
                type: '',
                countryCode: '',
                priority: fields.length + 1,
              })
            }>
            {t('driver.addNumber')}
          </EhiButton>
        </GridItem>
      )}
    </>
  );
};

export const ContactSection: FC = () => {
  const { t } = useTranslations();
  const { setValue } = useFormContext();
  const driverType = useWatch({ name: DriverFormFields.DriverType });

  return (
    <Section data-testid={'contactSection'}>
      <FormSectionHeader>{t('driver.contact')}</FormSectionHeader>
      <div className={piiField}>
        <GridContainer>
          <PhoneSection />
          <GridItem sm={6}>
            <FormTextField
              name={DriverFormFields.Email}
              label={t('driver.email')}
              data-testid={'emailInput'}
              disabled={
                driverType === DriverType.LOYALTY_DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR
              }
              onBlur={(event: any): void => setValue(DriverFormFields.Email, event.target.value)}
            />
          </GridItem>
        </GridContainer>
      </div>
    </Section>
  );
};
