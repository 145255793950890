import { FC, ReactElement, useMemo } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { Box, Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, ehiTheme } from '@ehi/ui';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import {
  RowItem,
  RowItemLabel,
  RowItemText,
} from 'components/flexFlow/rateAndBilling/rateQualificationsDialog/RateQualificationsDialog.styles';
import { DayOfWeekAndTime, HoursOrDays, RateQualifications } from 'services/booking/bookingTypes';
import {
  DayOfWeek,
  DayOfWeekFlag,
  DAYS,
  HOURS,
  RateQualificationColumn,
  RateQualificationRowKeys,
  RateQualificationRows,
} from 'components/flexFlow/rateAndBilling/rateQualificationsDialog/RateQualificationsDialogTypes';
import { parseUrn } from 'utils/urnUtils';
import { convertTimeTo12HourFormat } from 'utils/dateUtils';

export type RateQualificationsDialogProps = {
  open: boolean;
  rateQualifications: RateQualifications | undefined;
  closeModal: () => void;
};

export const RateQualificationsDialog: FC<RateQualificationsDialogProps> = ({
  open,
  rateQualifications,
  closeModal,
}) => {
  const { t } = useTranslations();
  const tableRows = useMemo(() => {
    const rows: RateQualificationRows = new Map();
    rows.set(
      RateQualificationRowKeys.MUST_INCLUDE,
      Object.values(DayOfWeek)?.map((dayOfWeek) => {
        const rateQualDayOfWeek = rateQualifications?.dayOfTheWeekRequirement?.find(
          (value) => value.dayOfWeek === dayOfWeek.valueOf()
        );

        return {
          id: dayOfWeek,
          label: rateQualDayOfWeek && rateQualDayOfWeek.mustInclude ? DayOfWeekFlag.YES : DayOfWeekFlag.NO,
        };
      })
    );
    rows.set(
      RateQualificationRowKeys.MUST_EXCLUDE,
      Object.values(DayOfWeek)?.map((dayOfWeek) => {
        const rateQualDayOfWeek = rateQualifications?.dayOfTheWeekRequirement?.find(
          (value) => value.dayOfWeek === dayOfWeek.valueOf()
        );

        return {
          id: dayOfWeek,
          label: rateQualDayOfWeek && rateQualDayOfWeek.mustExclude ? DayOfWeekFlag.YES : DayOfWeekFlag.NO,
        };
      })
    );
    rows.set(
      RateQualificationRowKeys.REQUIRED_OVERNIGHT,
      Object.values(DayOfWeek)?.map((dayOfWeek) => {
        const rateQualDayOfWeek = rateQualifications?.dayOfTheWeekRequirement?.find(
          (value) => value.dayOfWeek === dayOfWeek.valueOf()
        );

        return {
          id: dayOfWeek,
          label: rateQualDayOfWeek && rateQualDayOfWeek.overnightRequired ? DayOfWeekFlag.YES : DayOfWeekFlag.NO,
        };
      })
    );

    return rows;
  }, [rateQualifications?.dayOfTheWeekRequirement]);

  const getDurationTextBody = (durationInfo: HoursOrDays | undefined): string => {
    if (!durationInfo) {
      return DOUBLE_DASH;
    }
    let unitText = EMPTY_VALUE;
    // singular verbiage
    if (durationInfo.value === 1) {
      if (durationInfo.unit === HOURS) {
        unitText = t('rateAndBilling.rateQualifications.hour');
      } else if (durationInfo.unit === DAYS) {
        unitText = t('rateAndBilling.rateQualifications.day');
      }
    } else {
      // plural verbiage
      if (durationInfo.unit === HOURS) {
        unitText = t('rateAndBilling.rateQualifications.hours');
      } else if (durationInfo.unit === DAYS) {
        unitText = t('rateAndBilling.rateQualifications.days');
      }
    }

    return `${durationInfo.value} ${unitText}`;
  };

  const getMinDaysChargedTextBody = (durationInfo: number | undefined): string => {
    if (!durationInfo) {
      return DOUBLE_DASH;
    }
    let unitText = EMPTY_VALUE;
    // singular verbiage
    if (durationInfo === 1) {
      unitText = t('rateAndBilling.rateQualifications.day');
    } else {
      // plural verbiage
      unitText = t('rateAndBilling.rateQualifications.days');
    }

    return `${durationInfo} ${unitText}`;
  };

  const getWeekdayAbbr = (weekday: string): string => {
    switch (weekday) {
      case DayOfWeek.Sun:
        return t('rateAndBilling.rateQualifications.sun');
      case DayOfWeek.Mon:
        return t('rateAndBilling.rateQualifications.mon');
      case DayOfWeek.Tue:
        return t('rateAndBilling.rateQualifications.tue');
      case DayOfWeek.Wed:
        return t('rateAndBilling.rateQualifications.wed');
      case DayOfWeek.Thu:
        return t('rateAndBilling.rateQualifications.thu');
      case DayOfWeek.Fri:
        return t('rateAndBilling.rateQualifications.fri');
      case DayOfWeek.Sat:
        return t('rateAndBilling.rateQualifications.sat');
      default:
        return EMPTY_VALUE;
    }
  };

  const getRentalTimeTextBody = (timeInfo: DayOfWeekAndTime | undefined): string => {
    if (!timeInfo) {
      return DOUBLE_DASH;
    }

    const timeString = timeInfo.time ? convertTimeTo12HourFormat(timeInfo.time) : EMPTY_VALUE;
    const weekdayString = timeInfo.dayOfWeek ? getWeekdayAbbr(timeInfo.dayOfWeek) : undefined;

    return weekdayString ? `${weekdayString} ${timeString}` : timeString;
  };

  const createTableRowView = (
    key: RateQualificationRowKeys,
    columns: RateQualificationColumn[] | undefined
  ): ReactElement => {
    return (
      <TableRow data-testid={`row-${key}`} key={key} sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}>
        <RowItem component='th' scope='row' sx={{ borderRight: '1px solid #0000003b' }}>
          <RowItemLabel>{t(key)}</RowItemLabel>
        </RowItem>
        {columns?.map((col) => (
          <TableRowItem key={col.id} id={col.id} textBody={col.label} bold={col.label === DayOfWeekFlag.YES} />
        ))}
      </TableRow>
    );
  };

  return (
    <Dialog
      data-testid='rateQualificationsDialog'
      id='rateQualificationsDialog'
      contentPadding={0}
      open={open}
      title={t('rateAndBilling.rateQualifications.rateQualifications')}
      a11yKey='content'
      maxWidth={'md'}
      showDividers={true}
      actions={{
        primaryAction: {
          label: t('common.close'),
          onClick: closeModal,
        },
      }}>
      <Box padding={ehiTheme.spacing(3)}>
        <Box padding={ehiTheme.spacing(1)} style={{ backgroundColor: ehiTheme.palette.background.default }}>
          <Box display={'flex'} data-testid='topSection'>
            <Grid container display={'flex'} flexDirection={'column'} paddingRight={ehiTheme.spacing(3)}>
              <TopGridLeftItem
                id='qualId'
                label={t('rateAndBilling.qualId')}
                textBody={parseUrn(rateQualifications?.id)}
              />
              <TopGridLeftItem
                id='minRental'
                label={t('rateAndBilling.rateQualifications.minRental')}
                textBody={getDurationTextBody(rateQualifications?.durationRequirements?.minimum)}
              />
              <TopGridLeftItem
                id='maxRental'
                label={t('rateAndBilling.rateQualifications.maxRental')}
                textBody={getDurationTextBody(rateQualifications?.durationRequirements?.maximum)}
              />
              <TopGridLeftItem
                id='minDaysCharged'
                label={t('rateAndBilling.rateQualifications.minDaysCharged')}
                textBody={getMinDaysChargedTextBody(rateQualifications?.minimumDaysCharged)}
              />
            </Grid>
            <Grid container display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(3)}>
              <TopGridRightItem
                id='rentalStart'
                label={t('rateAndBilling.rateQualifications.rentalStart')}
                textBody={getRentalTimeTextBody(rateQualifications?.schedulingRequirements?.start?.earliestDayAndTime)}
              />
              <TopGridRightItem
                id='rentalLatest'
                label={t('rateAndBilling.rateQualifications.rentalLatest')}
                textBody={getRentalTimeTextBody(rateQualifications?.schedulingRequirements?.start?.latestDayAndTime)}
              />
              <TopGridRightItem
                id='returnLatest'
                label={t('rateAndBilling.rateQualifications.returnLatest')}
                textBody={getRentalTimeTextBody(rateQualifications?.schedulingRequirements?.end?.latestDayAndTime)}
              />
            </Grid>
          </Box>
        </Box>
        <Box>
          <TableContainer component={TableContainer} sx={{ paddingTop: ehiTheme.spacing(3) }}>
            <Table size={'small'}>
              <TableHead>
                <TableRow>
                  {/* Placeholder for empty first item in column and row so the items line up properly*/}
                  <RowItem align='right' sx={{ borderRight: '1px solid #0000003b' }}>
                    {EMPTY_VALUE}
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Sun)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Mon)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Tue)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Wed)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Thu)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Fri)}</RowItemLabel>
                  </RowItem>
                  <RowItem align='right'>
                    <RowItemLabel>{getWeekdayAbbr(DayOfWeek.Sat)}</RowItemLabel>
                  </RowItem>
                </TableRow>
              </TableHead>
              <TableBody>
                {createTableRowView(
                  RateQualificationRowKeys.MUST_INCLUDE,
                  tableRows.get(RateQualificationRowKeys.MUST_INCLUDE)
                )}
                {createTableRowView(
                  RateQualificationRowKeys.MUST_EXCLUDE,
                  tableRows.get(RateQualificationRowKeys.MUST_EXCLUDE)
                )}
                {createTableRowView(
                  RateQualificationRowKeys.REQUIRED_OVERNIGHT,
                  tableRows.get(RateQualificationRowKeys.REQUIRED_OVERNIGHT)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Dialog>
  );
};

type TopGridItem = {
  id: string;
  label: string;
  textBody?: string;
};

const TopGridLeftItem = ({ id, label, textBody }: TopGridItem): ReactElement => {
  return (
    <Grid container data-testid={id} display={'flex'} spacing={2} justifyContent={'space-between'}>
      <Grid item xs={7} sm={7}>
        <Caption2>{label}</Caption2>
      </Grid>
      <Grid item xs={5} sm={5} textAlign={'start'}>
        <Body1>{textBody}</Body1>
      </Grid>
    </Grid>
  );
};

const TopGridRightItem = ({ id, label, textBody }: TopGridItem): ReactElement => {
  return (
    <Grid container data-testid={id} display={'flex'} spacing={2} justifyContent={'space-between'}>
      <Grid item xs={6} sm={6}>
        <Caption2>{label}</Caption2>
      </Grid>
      <Grid item xs={6} sm={6} textAlign={'start'}>
        <Body1>{textBody}</Body1>
      </Grid>
    </Grid>
  );
};

const TableRowItem = ({
  id,
  textBody,
  bold = false,
}: {
  id: string;
  textBody: string;
  bold?: boolean;
}): ReactElement => {
  return (
    <RowItem align='center'>
      <RowItemText data-testid={`rowItem-${id}`} bold={bold}>
        {textBody}
      </RowItemText>
    </RowItem>
  );
};
