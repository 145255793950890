import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { CSSProperties, JSX } from 'react';
import { NavigateFunction, To } from 'react-router-dom';

export enum SelectedAction {
  Primary,
  Secondary,
  Tertiary,
}
export type BaseDialogProps = {
  open: boolean;
  onClose?: (selected?: SelectedAction) => void;
};

export type AlertDialogVariant = 'error' | 'warning' | 'alert' | 'destructive';

export type AlertDialogProps = {
  title?: string;
  subtitle?: string;
  description?: string | JSX.Element;
  descriptionStyle?: CSSProperties;
  showMoreMenu?: boolean;
  primaryActionText?: string;
  secondaryActionText?: string;
  tertiaryActionText?: string;
  collectErrorInformation?: () => string;
  variant?: AlertDialogVariant;
};

export type AlertWithResponseMessages = AlertDialogProps & {
  description?: never;
  responseMessages: ResponseMessage[] | undefined;
  additionalMessage?: string;
};

export type AlertWithDescription = AlertDialogProps & {
  description: string | JSX.Element;
  responseMessages?: never;
};

export type AlertWithActions = AlertDialogProps & {
  description?: never;
  responseMessages?: never;
  descriptions: Array<DescriptionWithAction>;
  navigate: NavigateFunction;
};

export type AlertOptions = AlertWithResponseMessages | AlertWithDescription | AlertWithActions;

export type AlertProps = AlertDialogProps & AlertOptions;

export type AlertContextProps = {
  showAlert: (options: AlertProps) => Promise<SelectedAction | undefined>;
  hideAlert: (selected?: SelectedAction) => void;
};

export type DescriptionWithAction = {
  message: string;
  routePath?: To;
};

export type AlertWithActionProps = {
  description: DescriptionWithAction;
  alertVariant: AlertDialogVariant | undefined;
  navigate: NavigateFunction;
};
