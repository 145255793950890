import { useDomainQuery } from 'context/queryClient/queryUtil';
import { retrieveAllCardBrands } from 'services/payment/paymentReferenceService';
import { UseQueryResult } from '@tanstack/react-query';
import { CardBrand } from 'services/payment/paymentReferenceTypes';
import { EhiErrors } from 'services/types/EhiErrorsTypes';

const CARD_BRAND_QUERY_KEY = 'cardBrand';

export const useCardBrandsQuery = (): UseQueryResult<CardBrand[], EhiErrors> => {
  return useDomainQuery({ queryKey: CARD_BRAND_QUERY_KEY, queryFn: () => retrieveAllCardBrands() });
};
