import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { ServiceState } from 'services/types/ServiceStateTypes';

export interface DropDownOption {
  text: string;
}

export enum FieldType {
  DROP_DOWN = 'DROP_DOWN',
  TEXT = 'TEXT',
  DATE = 'DATE',
}

export interface DropDownField {
  type: FieldType.DROP_DOWN;
  dropDownOption: DropDownOption[];
}

export interface TextField {
  type: FieldType.TEXT;
}

export type FieldInfo = DropDownField | TextField;

export interface UIAdditionalInfoField {
  fieldValue?: string;
  fieldName: string;
  fieldId: string;
  helperText: string;
  isRequiredAtReservation: boolean;
  fieldInfo: FieldInfo;
  ordinal?: number;
  dataType?: string;
}

export enum AdditionalInfoAccountType {
  RATE_SOURCE,
  BILL_TO,
}

type BaseAdditionalInfoData = {
  accountNumber: string;
  additionalInfoFields: UIAdditionalInfoField[];
  accountName?: string;
  accountUrn?: string;
};

type RateSourceAdditionalInfoData = BaseAdditionalInfoData & {
  type: AdditionalInfoAccountType.RATE_SOURCE;
};

export type BillToAdditionalInfoData = BaseAdditionalInfoData & {
  type: AdditionalInfoAccountType.BILL_TO;
  billingNumber?: string;
};

export type AdditionalInfoData = RateSourceAdditionalInfoData | BillToAdditionalInfoData;

export type AdditionalInformationTypes = {
  fieldId: string;
  value: string;
};

export type SaveRequestsTypes = {
  account: string;
  type: AdditionalInfoAccountType;
  additionalInformation: AdditionalInformationTypes[];
  accountNumber: string;
  billingNumber: string;
};

export type EditAdditionalInfoProps = {
  additionalInfoData: AdditionalInfoData;
  open: boolean;
  onClose: (selectedAction: SelectedAction) => void;
};

export type SaveAdditionalInfoHookReturn = {
  saveAdditionalInfo: (saveAdditionalInfo: SaveRequestsTypes) => Promise<void>;
};

export type AdditionalInfoHookReturn = ServiceState & {
  additionalInfo: AdditionalInfoData;
};
