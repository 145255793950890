import { AnySchema, object, string, AnyObject, ObjectSchema } from 'yup';
import {
  AdditionalInfoData,
  AdditionalInformationTypes,
  BillToAdditionalInfoData,
  FieldType,
  SaveRequestsTypes,
  UIAdditionalInfoField,
} from './AdditionalInfoTypes';
import { parseUrn } from 'utils/urnUtils';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { TFunction } from 'i18next';
import { ActionOptions } from '@ehi/ui';
import { DateTime } from 'luxon';
import { toDateTime } from 'utils/dateUtils';

export const validationSchema = (
  additionalInfoData: AdditionalInfoData,
  t: TFunction<'translation'>
): ObjectSchema<
  {
    [x: string]: string;
  },
  AnyObject,
  {
    [x: string]: string;
  },
  ''
> => {
  const schema = {} as Record<string, AnySchema>;
  additionalInfoData.additionalInfoFields.forEach((field: UIAdditionalInfoField) => {
    let validator = string();
    if (field.isRequiredAtReservation) {
      validator = validator.required(t('validation.requiredField'));
    }
    schema[`${additionalInfoData.accountNumber}_${parseUrn(field.fieldId)}`] = validator;
  });
  return object().shape(schema);
};

export const getActions = (
  onClose: (actions: SelectedAction) => void,
  onSubmit: () => Promise<void>,
  t: TFunction<'translation'>
): ActionOptions => {
  return {
    primaryAction: {
      label: t('common.save'),
      onClick: onSubmit,
    },
    secondaryAction: {
      label: t('common.cancel'),
      onClick: () => onClose(SelectedAction.Secondary),
    },
  };
};

export const getInitialValues = (
  additionalInfo: AdditionalInfoData,
  t: TFunction<'translation'>
): Record<string, string | DateTime<boolean> | undefined> => {
  const allFields = {} as Record<string, string | DateTime<boolean> | undefined>;
  additionalInfo.additionalInfoFields.forEach((field) => {
    allFields[`${additionalInfo.accountNumber}_${parseUrn(field.fieldId)}`] =
      field.dataType === FieldType.DATE
        ? toDateTime(
            DateTime.fromFormat(field.fieldValue ?? '', t('format.monthDayYearWithNoSpecialCharacters')).toISO()
          )
        : field.fieldValue ?? '';
  });
  return allFields;
};

export const generateSaveRequests = (
  values: Record<string, string | DateTime<boolean> | undefined>,
  additionalInfo: AdditionalInfoData,
  t: TFunction<'translation'>
): SaveRequestsTypes => {
  const additionalFields = Object.entries(values).map(([key, value]) => {
    const [accountNumber, fieldId] = key.split('_');
    const fieldData = additionalInfo?.additionalInfoFields.find((x) => parseUrn(x.fieldId) === fieldId);
    const isDateField = fieldData?.dataType === FieldType.DATE;

    if (accountNumber && fieldData) {
      return {
        fieldId: fieldData.fieldId,
        value: isDateField
          ? DateTime.fromISO(value as string).toFormat(t('format.monthDayYearWithNoSpecialCharacters'))
          : value,
      };
    }
  });

  return {
    account: additionalInfo.accountUrn || '',
    accountNumber: additionalInfo.accountNumber || '',
    billingNumber: (additionalInfo as BillToAdditionalInfoData).billingNumber || '',
    type: additionalInfo.type,
    additionalInformation: additionalFields as AdditionalInformationTypes[],
  };
};
