import { Box, CardContent } from '@mui/material';
import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { RouterPaths } from 'app/router/RouterPaths';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { FC } from 'react';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';

export const RateSourceAndBillToSummary: FC = () => {
  const { t } = useTranslations();
  const { rateSourceInfo, isRateSourceInfoLoading, rateSourceErrors, billingAccountInfo, isBillingAccountInfoLoading } =
    useAccountDetails();

  return (
    <ReservationSummaryCard
      title={t('reservationSummary.rateAndBilling')}
      loading={mapLoadingState(isRateSourceInfoLoading || isBillingAccountInfoLoading, !!rateSourceErrors)}
      path={RouterPaths.RateAndBill}
      errors={[...(rateSourceErrors?.errors || [])]}
      data-testid='rateSourceSummaryCard'>
      <CardContent data-testid='rateSourceSummaryContent' style={{ paddingBottom: 0 }}>
        <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(1)} data-testid='rateSourceDetails'>
          <Caption2>{t('rateAndBilling.rateSource')}</Caption2>
          <Subtitle1 bold data-testid='rateSourceAccountName'>
            {rateSourceInfo?.name ?? t('rateAndBilling.retail')}
          </Subtitle1>
          {rateSourceInfo?.type && <Body2 data-testid='rateSourceAccountType'>{rateSourceInfo.type}</Body2>}
        </Box>
        {billingAccountInfo?.name && (
          <Box display={'flex'} flexDirection={'column'} paddingTop={ehiTheme.spacing(2)} data-testid='billToDetails'>
            <Caption2>{t('rateAndBilling.billTo.pageTitle')}</Caption2>
            <Subtitle1 bold data-testid='billingAccountName'>
              {billingAccountInfo.name}
            </Subtitle1>
          </Box>
        )}
      </CardContent>
    </ReservationSummaryCard>
  );
};
