import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { MaxLengthTextFieldProps } from './FormFieldTypes';

export const MaxLengthTextField: FC<MaxLengthTextFieldProps> = ({ maxLength, ...rest }) => {
  const { setValue } = useFormContext();
  return (
    <FormTextField
      {...rest}
      onChange={(value: ChangeEvent<HTMLInputElement>): void => {
        if (maxLength) {
          if (value.currentTarget.value?.length <= maxLength) {
            setValue(rest.name, value.currentTarget.value, { shouldDirty: true });
          }
        } else {
          setValue(rest.name, value.currentTarget.value, { shouldDirty: true });
        }
        if (rest.onChange) {
          rest.onChange(value);
        }
      }}
    />
  );
};
