import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import {
  selectBookingEditorId,
  selectEquipment,
  selectPickup,
  selectProtections,
  selectReturn,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { WhenAndWhereFields, WhenAndWhereValues } from 'components/flexFlow/whenAndWhere/WhenAndWhereTypes';
import { useForm } from 'react-hook-form';
import { RentalStartSection } from 'components/flexFlow/whenAndWhere/RentalStartSection';
import { RentalReturnSection } from 'components/flexFlow/whenAndWhere/RentalReturnSection';
import { compareDateTime, toDateTime } from 'utils/dateUtils';
import { useMemo, useState } from 'react';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { updatePickupInformation, updateReturnInformation } from 'services/booking/bookingService';
import { DateTime } from 'luxon';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { whenAndWhereValidationSchema } from 'components/flexFlow/whenAndWhere/whenAndWhereValidationSchema';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { useSaveWhenAndWhere } from 'components/flexFlow/whenAndWhere/useSaveWhenAndWhere';
import { ErrorBanner } from 'components/shared/errors/ErrorBanner';
import { Grid } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { RentalCard } from 'components/flexFlow/whenAndWhere/WhenAndWhere.styles';
import { useBookingIssue } from 'services/booking/useBookingIssue';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import {
  getAllBillToBookingIssues,
  getAllVehicleNotAvailableBookingIssues,
  getDriverProfileBookingIssue,
  getPeoBookingIssues,
  getVehicleYoungDriverBookingIssue,
  hasVehicleAvailabilityBookingIssues,
} from 'utils/bookingUtils';
import { formattedEquipmentTypes, getPeoTitle } from 'utils/peoUtils';
import { parseUrn } from 'utils/urnUtils';
import { useEquipmentTypesQuery, useProtectionTypesQuery } from 'services/rentalReference/rentalReferenceQueries';
import { EMPTY_VALUE } from 'utils/constants';
import { AvailableBookingIssue } from 'hooks/bookingEditor/useRefreshEditor';

export const WhenAndWhere: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { getLocalizedDateTime } = useDateTimeFormater();
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const vehicle = useAppSelector(selectVehicleClassSelection);
  const equipment = useAppSelector(selectEquipment);
  const { data: equipmentDetails = [] } = useEquipmentTypesQuery();
  const protections = useAppSelector(selectProtections);
  const { data: protectionDetails = [] } = useProtectionTypesQuery();
  const [loading, setLoading] = useState(false);
  const [editorError, setEditorError] = useState(false);
  const { save, handleErrors } = useSaveWhenAndWhere();
  const { handleRemoveVehicle, handleRemoveDriverProfile, handleRemoveAllPeo, handleRemoveBillTo } = useBookingIssue();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const bookingEditorId = useAppSelector(selectBookingEditorId);

  const {
    data: pickupBranch,
    isFetching: isFetchingPickupBranch,
    isError: isPickupBranchError,
  } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const {
    data: returnBranch,
    isFetching: isFetchingReturnBranch,
    isError: isReturnBranchError,
  } = useBranchInfoByUrnQuery(returnData?.branch ?? '');

  const isFetching = isFetchingPickupBranch || isFetchingReturnBranch || loading;
  const isError = isPickupBranchError || isReturnBranchError || editorError;

  const whenWhereInitialValues: WhenAndWhereValues = useMemo(() => {
    return {
      startDate: toDateTime(pickupData?.dateTime, pickupBranch?.timezone) ?? '',
      startTime: toDateTime(pickupData?.dateTime, pickupBranch?.timezone) ?? '',
      returnDate: toDateTime(returnData?.dateTime, returnBranch?.timezone) ?? '',
      returnTime: toDateTime(returnData?.dateTime, returnBranch?.timezone) ?? '',
      startLocation: pickupBranch?.stationId ?? '',
      returnLocation: returnBranch?.stationId ?? '',
      startLocationCurrentTime: pickupBranch?.timezone
        ? (getLocalizedDateTime(pickupBranch.timezone, DateTime.now()) as DateTime)
        : '',
      returnLocationCurrentTime: returnBranch?.timezone
        ? (getLocalizedDateTime(returnBranch?.timezone, DateTime.now()) as DateTime)
        : '',
      startLocationUrn: pickupBranch?.urn ?? '',
      returnLocationUrn: returnBranch?.urn ?? '',
      startDateTime: pickupData?.dateTime ?? '',
      returnDateTime: returnData?.dateTime ?? '',
      startLocationTimezone: pickupBranch?.timezone ?? undefined,
      returnLocationTimezone: returnBranch?.timezone ?? undefined,
    };
  }, [pickupData, returnData, pickupBranch, returnBranch, getLocalizedDateTime]);

  const getValidationSchema = useMemo(
    () =>
      whenAndWhereValidationSchema(
        t,
        pickupBranch?.timezone ? (getLocalizedDateTime(pickupBranch.timezone, DateTime.now()) as DateTime) : ''
      ),
    [t, pickupBranch, getLocalizedDateTime]
  );

  const resolver = useYupValidationResolver(getValidationSchema);

  const formMethods = useForm<WhenAndWhereValues>({
    defaultValues: whenWhereInitialValues,
    resolver: resolver,
  });

  const [startDate, startLocation, returnLocation, startDateTime, returnDateTime] = formMethods.watch([
    WhenAndWhereFields.StartDate,
    WhenAndWhereFields.StartLocation,
    WhenAndWhereFields.ReturnLocation,
    WhenAndWhereFields.StartDateTime,
    WhenAndWhereFields.ReturnDateTime,
  ]);
  const startDateTimeError = formMethods.formState.errors.startDate || formMethods.formState.errors.startTime;
  const showBannerMessage = useMemo(() => {
    if (startDateTime && returnDateTime) {
      if (compareDateTime(startDateTime, returnDateTime)) {
        formMethods.setError(WhenAndWhereFields.ReturnDate, {});
        formMethods.setError(WhenAndWhereFields.ReturnTime, {});

        return true;
      }
    }
    return false;
  }, [startDateTime, returnDateTime, formMethods]);

  const rentalCardClassName = useMemo(() => {
    const isRentalLocation =
      startLocation || returnLocation || whenWhereInitialValues.startLocation || whenWhereInitialValues.returnLocation;
    if (startDateTimeError && isRentalLocation) {
      return 'dateTimeRentalLocation';
    } else if (isRentalLocation) {
      return 'rentalLocation';
    } else if (startDateTimeError) {
      return 'dateTimeError';
    }
    return '';
  }, [startDateTimeError, startLocation, returnLocation, whenWhereInitialValues]);

  const getAvailableBookingIssuesForStartLocation = (): AvailableBookingIssue[] => {
    return [
      ...getAllVehicleNotAvailableBookingIssues(t, t('snackbarMessages.additionalInfo.location'), vehicle),
      ...getPeoBookingIssues(
        t,
        t('snackbarMessages.additionalInfo.location'),
        formattedEquipmentTypes(equipment, equipmentDetails).map((item) => item.title),
        protections?.map((item) => getPeoTitle(parseUrn(item.type), protectionDetails)) ?? []
      ),
      ...getAllBillToBookingIssues(t),
      getDriverProfileBookingIssue(t),
      getVehicleYoungDriverBookingIssue(t, vehicle?.preferred ? parseUrn(vehicle.preferred) : EMPTY_VALUE),
    ];
  };

  const handleResultForStartLocation = async (result: ServiceResultType): Promise<void> => {
    if (result.errors) {
      handleErrors(result.errors);
    }
    if (result.data?.issue) {
      if (hasVehicleAvailabilityBookingIssues(result.data.issue)) {
        await handleRemoveVehicle(result.data.issue);
      } else {
        await handleRemoveAllPeo(result.data.issue);
      }
      await handleRemoveBillTo(result.data.issue);
      await handleRemoveDriverProfile(result.data.issue);
    }
  };

  const handleRentalStartLocation = async (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ): Promise<void> => {
    setLoading(true);
    formMethods.setValue(WhenAndWhereFields.StartLocationUrn, branchUrn);
    formMethods.setValue(WhenAndWhereFields.StartLocationCurrentTime, locationCurrentTime ?? '');
    formMethods.setValue(WhenAndWhereFields.StartLocationTimezone, timezone ?? undefined);
    formMethods.clearErrors();
    if (pickupData && returnData) {
      let pickUpInfo = {
        branch: branchUrn,
      };
      const startDateTime = formMethods.getValues(WhenAndWhereFields.StartDateTime);
      pickUpInfo = { ...pickUpInfo, ...(startDateTime ? { dateTime: startDateTime } : {}) };

      await updateAndRefresh(() => updatePickupInformation(bookingEditorId, pickUpInfo), {
        availableBookingIssues: getAvailableBookingIssuesForStartLocation(),
      })
        .then(async (result) => {
          await handleResultForStartLocation(result);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      try {
        [
          { field: WhenAndWhereFields.StartLocationCurrentTime, value: locationCurrentTime ?? '' },
          { field: WhenAndWhereFields.StartLocationTimezone, value: timezone ?? undefined },
          { field: WhenAndWhereFields.ReturnLocationCurrentTime, value: locationCurrentTime ?? '' },
          { field: WhenAndWhereFields.ReturnLocation, value: stationId },
          { field: WhenAndWhereFields.StartLocationUrn, value: branchUrn ?? '' },
          { field: WhenAndWhereFields.ReturnLocationUrn, value: branchUrn ?? '' },
          { field: WhenAndWhereFields.ReturnLocationTimezone, value: locationCurrentTime?.zone.name ?? '' },
        ].forEach((item) => formMethods.setValue(item.field, item.value));

        await updateAndRefresh(
          async () => {
            await updatePickupInformation(bookingEditorId, {
              branch: branchUrn,
            });
            await updateReturnInformation(bookingEditorId, {
              branch: branchUrn,
            });
          },
          { availableBookingIssues: getAvailableBookingIssuesForStartLocation() }
        )
          .then(async (result) => {
            await handleResultForStartLocation(result);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setEditorError(true);
        formMethods.setError(WhenAndWhereFields.StartLocation, {
          message: (error as EhiErrors)?.errors?.[0]?.localizedMessage,
        });
      }
    }
  };

  const handleRentalReturnLocation = async (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ): Promise<void> => {
    // Clear Errors when we change the location.
    formMethods.clearErrors();
    [
      { field: WhenAndWhereFields.ReturnLocationUrn, value: branchUrn ?? '' },
      { field: WhenAndWhereFields.ReturnLocation, value: stationId },
      { field: WhenAndWhereFields.ReturnLocationTimezone, value: timezone ?? undefined },
      { field: WhenAndWhereFields.ReturnLocationCurrentTime, value: locationCurrentTime ?? '' },
    ].forEach((item) => formMethods.setValue(item.field, item.value));
    let returnInfo = {
      branch: branchUrn,
    };
    const returnDateTime = formMethods.getValues(WhenAndWhereFields.ReturnDateTime);
    returnInfo = { ...returnInfo, ...(returnDateTime ? { dateTime: returnDateTime } : {}) };
    setLoading(true);
    await updateAndRefresh(() => updateReturnInformation(bookingEditorId, returnInfo), {
      availableBookingIssues: [
        ...getAllVehicleNotAvailableBookingIssues(t, t('snackbarMessages.additionalInfo.location'), vehicle),
        ...getAllBillToBookingIssues(t),
      ],
    })
      .then(async (result) => {
        if (result.errors) {
          handleErrors(result.errors);
        }
        if (result.data?.issue)
          if (hasVehicleAvailabilityBookingIssues(result.data.issue)) {
            await handleRemoveVehicle(result.data.issue);
          }
        await handleRemoveBillTo(result.data.issue);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (values: WhenAndWhereValues): Promise<ServiceResultType> => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  return (
    <FlexFlowCard loadingState={mapLoadingState(isFetching, isError)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <SavingFormProvider
        {...formMethods}
        submitFn={(values: WhenAndWhereValues): Promise<ServiceResultType> => handleSubmit(values)}>
        <Grid container display={'flex'}>
          {showBannerMessage && (
            <Grid item sm={12} margin={ehiTheme.spacing(8, 2, -10, 0)}>
              <ErrorBanner errorMessage={'validation.returnDateTimeError'} />
            </Grid>
          )}
          <Grid item xs>
            <RentalCard className={rentalCardClassName} variant={'outlined'} sx={{ marginRight: ehiTheme.spacing(1) }}>
              <RentalStartSection
                pickupBranch={pickupBranch}
                pickupDateTime={pickupData?.dateTime}
                handleRentalStartLocation={handleRentalStartLocation}
              />
            </RentalCard>
          </Grid>
          <Grid item xs>
            <RentalCard className={rentalCardClassName} variant={'outlined'} sx={{ marginLeft: ehiTheme.spacing(1) }}>
              <RentalReturnSection
                returnBranch={returnBranch}
                returnDateTime={returnData?.dateTime}
                handleRentalReturnLocation={handleRentalReturnLocation}
                startDate={startDate ? (startDate as DateTime) : undefined}
              />
            </RentalCard>
          </Grid>
        </Grid>
      </SavingFormProvider>
    </FlexFlowCard>
  );
};
