import { useCallback } from 'react';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { useTranslations } from 'components/shared/i18n';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useGetAdditionalDriverProfiles } from 'services/renter/useGetAdditionalDriverProfiles';

export const useDriverLicenseValidation = (): {
  validateExpirationDateAgainstRentalDates: (date: string) => void;
  validateRentalDatesAgainstExpirationDate: (date: string) => void;
} => {
  const { showAlert } = useAlert();
  const rentalPickup = useAppSelector(selectPickup);
  const rentalReturn = useAppSelector(selectReturn);
  const { driverData: primaryDriverData } = useGetRenterProfile();
  const { data: additionalDriversData } = useGetAdditionalDriverProfiles();

  const { t } = useTranslations();

  /**
   * - Validates driver license does not expire before or during rental period.
   *
   * @param date Driver license expiration date
   */
  const validateExpirationDateAgainstRentalDates = useCallback(
    (date: string) => {
      if (!(rentalPickup?.dateTime || rentalReturn?.dateTime) || !date) {
        return;
      }

      const pickupOrReturnDate = rentalReturn?.dateTime || rentalPickup?.dateTime;
      if (pickupOrReturnDate) {
        const rentalDate = new Date(pickupOrReturnDate);
        const driverLicenseExpirationDate = new Date(date);

        const rentalDateWithoutTimezone = new Date(
          rentalDate.getFullYear(),
          rentalDate.getMonth(),
          rentalDate.getDate()
        );
        const driverLicenseExpirationDateWithoutTimezone = new Date(
          driverLicenseExpirationDate.getFullYear(),
          driverLicenseExpirationDate.getMonth(),
          driverLicenseExpirationDate.getDate()
        );

        if (driverLicenseExpirationDateWithoutTimezone <= rentalDateWithoutTimezone) {
          void showAlert({
            title: t('driver.warnings.expDateInPastTitle'),
            description: t('driver.warnings.expDateInPast'),
          });
        }
      }
    },
    [rentalPickup?.dateTime, rentalReturn?.dateTime, showAlert, t]
  );

  /**
   * - Validates rental date does not fall after driver license expiration date of all drivers.
   *
   * @param date Rental pickup or return date
   */
  const validateRentalDatesAgainstExpirationDate = useCallback(
    (date: string) => {
      const driverHasExpiredLicense = [primaryDriverData, ...additionalDriversData]?.some((driver) => {
        if (!driver || !driver.legalIdentification || !driver?.legalIdentification?.expirationDate) {
          return false;
        }

        if (driver.legalIdentification.expirationDate) {
          const rentalDate = new Date(date);
          const driverLicenseExpirationDate = new Date(driver.legalIdentification.expirationDate);

          const rentalDateWithoutTimezone = new Date(
            rentalDate.getFullYear(),
            rentalDate.getMonth(),
            rentalDate.getDate()
          );
          const driverLicenseExpirationDateWithoutTimezone = new Date(
            driverLicenseExpirationDate.getFullYear(),
            driverLicenseExpirationDate.getMonth(),
            driverLicenseExpirationDate.getDate()
          );

          if (driverLicenseExpirationDateWithoutTimezone < rentalDateWithoutTimezone) {
            return true;
          }
        }
        return false;
      });

      if (driverHasExpiredLicense) {
        void showAlert({
          title: t('driver.warnings.expDateInPastTitle'),
          description: t('driver.warnings.expDateInPast'),
        });
      }
    },
    [additionalDriversData, primaryDriverData, showAlert, t]
  );

  return {
    validateExpirationDateAgainstRentalDates,
    validateRentalDatesAgainstExpirationDate,
  };
};
